import React from 'react';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';
import waitUtil from '../utils/wait';

const defaultProps = {
  token:
    '',
};

const UnsubscribeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const urlDecode = str => {
    // console.log('urlDecode',str, decodeURIComponent(str.replace(/\+/g,' ')));
    if (Platform.OS === 'web' && str) {
      return decodeURIComponent(str?.replace(/\+/g, ' '));
    }
    return str;
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
        },
        dimensions.width
      )}
    >
      {/* ResponsiveWrapper */}
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].style,
            {
              alignItems: { minWidth: Breakpoints.BigScreen, value: 'center' },
              width: dimensions.width,
            }
          ),
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'center',
              margin: 8,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              {...GlobalStyles.ImageStyles(theme)['Image'].props}
              source={imageSource(Images['LogoWhiteFull'])}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ImageStyles(theme)['Image'].style,
                  { height: 50, width: 150 }
                ),
                dimensions.width
              )}
            />
          </View>
        </View>
        {/* Body */}
        <View
          style={StyleSheet.applyWidth(
            {
              flex: [
                { minWidth: Breakpoints.Desktop, value: 1 },
                { minWidth: Breakpoints.Mobile, value: 1 },
              ],
              overflow: 'hidden',
              paddingLeft: 12,
              paddingRight: 12,
            },
            dimensions.width
          )}
        >
          <NewXANOAPIApi.FetchGetEmailToUnsubscribePOST
            token={props.route?.params?.token ?? defaultProps.token}
          >
            {({ loading, error, data, refetchGetEmailToUnsubscribe }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return (
                  <>
                    {/* Error View */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flex: 1,
                          justifyContent: 'center',
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['Custom #ffffff'],
                              fontFamily: 'SpaceGrotesk_600SemiBold',
                              fontSize: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Wrong Url.'}
                      </Text>
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.App['Custom #ffffff'],
                              marginTop: 20,
                              textAlign: 'center',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'We could not locate the account associated to this unsubscribe url'
                        }
                      </Text>
                    </View>
                  </>
                );
              }

              return (
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', flex: 1, justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom #ffffff'],
                          fontFamily: 'SpaceGrotesk_600SemiBold',
                          fontSize: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Unsubscribing...'}
                  </Text>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          color: palettes.App['Custom #ffffff'],
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Are you sure you want to unsubscribe \n\n'}
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { color: theme.colors.branding.primary }
                        ),
                        dimensions.width
                      )}
                    >
                      {fetchData?.email}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { color: palettes.App['Custom #ffffff'] }
                          ),
                          dimensions.width
                        )}
                      >
                        {' from the mailing list?'}
                      </Text>
                    </Text>
                  </Text>
                  <Button
                    iconPosition={'left'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setGlobalVariableValue({
                            key: 'AUTH_TOKEN',
                            value: fetchData?.authToken,
                          });
                          await waitUtil({ milliseconds: 1000 });
                          const result = (
                            await NewXANOAPIApi.toggleEmailPermissionGET(
                              Constants
                            )
                          )?.json;
                          navigation.navigate('BottomTabNavigator', {
                            screen: 'DiscoverScreen',
                          });
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        { marginTop: 50 }
                      ),
                      dimensions.width
                    )}
                    title={'Yes, Unsubscribe'}
                  />
                </View>
              );
            }}
          </NewXANOAPIApi.FetchGetEmailToUnsubscribePOST>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UnsubscribeScreen);