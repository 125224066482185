import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const createPromotionPOST = async (
  Constants,
  { promo_category, promo_name, search_terms, valid_from, valid_till },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/promotions`;
  const options = {
    body: JSON.stringify({
      promo_name: promo_name,
      promo_category: promo_category,
      search_terms: search_terms,
      valid_from: valid_from,
      valid_till: valid_till,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreatePromotionPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createPromotionPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Promotions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Promotion');
        queryClient.invalidateQueries('Promotions');
      },
    }
  );
};

export const FetchCreatePromotionPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promo_category,
  promo_name,
  search_terms,
  valid_from,
  valid_till,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreatePromotionPOST(
    { promo_category, promo_name, search_terms, valid_from, valid_till },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreatePromotion: refetch });
};

export const createTagPOST = async (
  Constants,
  {
    actor,
    brand,
    category,
    charcter,
    episode,
    gender,
    genre,
    keywords,
    network,
    photo_base64,
    price,
    product_link,
    product_name,
    retailer,
    series_name,
    tag_name,
  },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/tags`;
  const options = {
    body: JSON.stringify({
      tag_name: tag_name,
      series_name: series_name,
      episode: episode,
      network: network,
      character: charcter,
      actor: actor,
      genre: genre,
      product_name: product_name,
      brand: brand,
      retailer: retailer,
      gender: gender,
      product_link: product_link,
      category: category,
      keywords: keywords,
      price: price,
      tag_photo: photo_base64,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCreateTagPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createTagPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Tags', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Tag');
        queryClient.invalidateQueries('Tags');
      },
    }
  );
};

export const FetchCreateTagPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  actor,
  brand,
  category,
  charcter,
  episode,
  gender,
  genre,
  keywords,
  network,
  photo_base64,
  price,
  product_link,
  product_name,
  retailer,
  series_name,
  tag_name,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateTagPOST(
    {
      actor,
      brand,
      category,
      charcter,
      episode,
      gender,
      genre,
      keywords,
      network,
      photo_base64,
      price,
      product_link,
      product_name,
      retailer,
      series_name,
      tag_name,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateTag: refetch });
};

export const deleteUnverifiedTagDELETE = async (
  Constants,
  { tagId },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/tags/${encodeQueryParam(
    tagId
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDeleteUnverifiedTagDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUnverifiedTagDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Tags', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Tag');
        queryClient.invalidateQueries('Tags');
      },
    }
  );
};

export const getAdsGET = async (
  Constants,
  { promotion_id, screen, searchQuery, tags_count, tags_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (searchQuery !== undefined) {
    paramsDict['searchQuery'] = renderParam(searchQuery);
  }
  if (promotion_id !== undefined) {
    paramsDict['promotion_id'] = renderParam(promotion_id);
  }
  if (tags_count !== undefined) {
    paramsDict['tags_count'] = renderParam(tags_count);
  }
  if (screen !== undefined) {
    paramsDict['screen'] = renderParam(screen);
  }
  if (tags_id !== undefined) {
    paramsDict['tags_id'] = renderParam(tags_id);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_ads${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetAdsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['ads', args], () => getAdsGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetAdsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotion_id,
  screen,
  searchQuery,
  tags_count,
  tags_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAdsGET(
    { promotion_id, screen, searchQuery, tags_count, tags_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAds: refetch });
};

export const getDiscoverButtonsGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/promotions`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetDiscoverButtonsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Promotion', args],
    () => getDiscoverButtonsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Promotions']),
    }
  );
};

export const FetchGetDiscoverButtonsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetDiscoverButtonsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetDiscoverButtons: refetch });
};

export const getLikedTagIdsGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_liked_tag_ids`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetLikedTagIdsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Liked Tag', args],
    () => getLikedTagIdsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Liked Tags']),
    }
  );
};

export const FetchGetLikedTagIdsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLikedTagIdsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLikedTagIds: refetch });
};

export const getPromotionGET = async (
  Constants,
  { promotions_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/promotions/${encodeQueryParam(
    promotions_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetPromotionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Promotion', args],
    () => getPromotionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Promotions']),
    }
  );
};

export const FetchGetPromotionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  promotions_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPromotionGET(
    { promotions_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetPromotion: refetch });
};

export const getTagDetailsGET = async (
  Constants,
  { tag_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_tags/${encodeQueryParam(
    tag_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTagDetailsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Tag', args],
    () => getTagDetailsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Tags']),
    }
  );
};

export const FetchGetTagDetailsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tag_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTagDetailsGET(
    { tag_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTagDetails: refetch });
};

export const getTagDetailsNoAuthGET = async (
  Constants,
  { tags_id },
  handlers = {}
) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_tags_noauth/${encodeQueryParam(
    tags_id
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTagDetailsNoAuthGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Tag', args],
    () => getTagDetailsNoAuthGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Tags']),
    }
  );
};

export const FetchGetTagDetailsNoAuthGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tags_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTagDetailsNoAuthGET(
    { tags_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetTagDetailsNoAuth: refetch,
  });
};

export const getTagsGET = async (
  Constants,
  { keywords, page, perPage, searchQuery },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = renderParam(page);
  }
  if (perPage !== undefined) {
    paramsDict['perPage'] = renderParam(perPage);
  }
  if (searchQuery !== undefined) {
    paramsDict['searchQuery'] = renderParam(searchQuery);
  }
  if (keywords !== undefined) {
    paramsDict['keywords'] = renderParam(keywords);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_tags${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTagsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['Tags', args], () => getTagsGET(Constants, args, handlers), {
    refetchInterval,
  });
};

export const FetchGetTagsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  keywords,
  page,
  perPage,
  searchQuery,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTagsGET(
    { keywords, page, perPage, searchQuery },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTags: refetch });
};

export const getTagsNoAuthGET = async (Constants, _args, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_tags_noauth`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetTagsNoAuthGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Tags', args],
    () => getTagsNoAuthGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetTagsNoAuthGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetTagsNoAuthGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetTagsNoAuth: refetch });
};

export const getUserLikedTagsGET = async (
  Constants,
  { page, perPage, user_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = renderParam(page);
  }
  if (perPage !== undefined) {
    paramsDict['perPage'] = renderParam(perPage);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/liked_tags/${encodeQueryParam(
    user_id
  )}${renderQueryString(paramsDict)}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUserLikedTagsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Liked Tags', args],
    () => getUserLikedTagsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserLikedTagsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
  perPage,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserLikedTagsGET(
    { page, perPage, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserLikedTags: refetch });
};

export const getUserTagsGET = async (
  Constants,
  { page, perPage, user_id },
  handlers = {}
) => {
  const paramsDict = {};
  if (page !== undefined) {
    paramsDict['page'] = renderParam(page);
  }
  if (perPage !== undefined) {
    paramsDict['perPage'] = renderParam(perPage);
  }
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/user_tags/${encodeQueryParam(
    user_id
  )}${renderQueryString(paramsDict)}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useGetUserTagsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Created Tags', args],
    () => getUserTagsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetUserTagsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  page,
  perPage,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserTagsGET(
    { page, perPage, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserTags: refetch });
};

export const shareTagPOST = async (Constants, { tags_id }, handlers = {}) => {
  const url = `https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/share_details`;
  const options = {
    body: JSON.stringify({ tags_id: tags_id }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['AUTH_TOKEN'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useShareTagPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['tagsAPIShareTagPOST', args],
    () => shareTagPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['tagsAPIShareTagPOSTS']),
    }
  );
};

export const FetchShareTagPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tags_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useShareTagPOST(
    { tags_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchShareTag: refetch });
};
