import React, { memo, useState } from 'react';
import { RefreshControl, ScrollView, Text, View } from 'react-native';

export const MasonryList = memo(props => {
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    refreshing,
    data,
    innerRef,
    ListHeaderComponent,
    ListEmptyComponent,
    ListFooterComponent,
    ListHeaderComponentStyle,
    containerStyle,
    contentContainerStyle,
    renderItem,
    onEndReachedThreshold,
    onEndReached,
    onRefresh,
    loading,
    LoadingView,
    numColumns = 2,
    horizontal,
    onScroll,
    onScrollEndDrag,
    removeClippedSubviews = false,
    keyExtractor,
    keyboardShouldPersistTaps = 'handled',
    refreshControl = true,
    refreshControlProps,
  } = props;

  const { style, ...propsWithoutStyle } = props;

  const isCloseToBottom = (
    { layoutMeasurement, contentOffset, contentSize },
    onEndReachedThreshold
  ) => {
    const paddingToBottom = contentSize.height * onEndReachedThreshold;

    return (
      Math.ceil(layoutMeasurement.height + contentOffset.y) >=
      contentSize.height - paddingToBottom
    );
  };

  return (
    <ScrollView
      {...propsWithoutStyle}
      ref={innerRef}
      style={[{ flex: 1, alignSelf: 'stretch' }, containerStyle]}
      contentContainerStyle={contentContainerStyle}
      keyboardShouldPersistTaps={keyboardShouldPersistTaps}
      removeClippedSubviews={removeClippedSubviews}
      refreshControl={
        refreshControl ? (
          <RefreshControl
            refreshing={!!(refreshing || isRefreshing)}
            onRefresh={() => {
              setIsRefreshing(true);
              onRefresh?.();
              setIsRefreshing(false);
            }}
            {...refreshControlProps}
          />
        ) : null
      }
      scrollEventThrottle={16}
      onScrollEndDrag={onScrollEndDrag}
      onScroll={e => {
        const nativeEvent = e.nativeEvent;
        if (isCloseToBottom(nativeEvent, onEndReachedThreshold || 0.0)) {
          onEndReached?.();
        }

        onScroll?.(e);
      }}
    >
      <>
        <View style={ListHeaderComponentStyle}>{ListHeaderComponent}</View>
        {data.length === 0 && ListEmptyComponent ? (
          React.isValidElement(ListEmptyComponent) ? (
            ListEmptyComponent
          ) : (
            // @ts-ignore
            <ListEmptyComponent />
          )
        ) : (
          <View
            style={[
              {
                flex: 1,
                flexDirection: horizontal ? 'column' : 'row',
              },
              style,
            ]}
          >
            {Array.from(Array(numColumns), (_, num) => {
              return (
                <View
                  key={`masonry-column-${num}`}
                  style={{
                    flex: 1 / numColumns,
                    flexDirection: horizontal ? 'row' : 'column',
                  }}
                >
                  {data
                    .map((el, i) => {
                      if (i % numColumns === num) {
                        return (
                          <View>
                            {/*
                                FOR DEBUG
                                <Text style={{color : "white"}}>{i}</Text>
                            */}
                            {renderItem({ item: el, i })}
                          </View>
                        );
                      }

                      return null;
                    })
                    .filter(e => !!e)}
                </View>
              );
            })}
          </View>
        )}
        {loading && LoadingView}
        {ListFooterComponent}
      </>
    </ScrollView>
  );
});
