import React from 'react';
import { Image } from 'react-native';

import { decode } from 'base-64'; // used for apple login
import moment from 'moment'; // used to display relative time stamps

const blurhash =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';
// const BlurhashEncoder = Blurhash.encode
const ExpoImage = ({ url, cardWidth, cardHeight, style = {} }) => {
  if (!url) {
    return (
      <Image
        style={{
          width: cardWidth,
          height: 175,
          ...style,
        }}
        placeholder={blurhash}
        contentFit="cover"
        transition={500}
      />
    );
  }

  return (
    <Image
      style={{
        width: cardWidth,
        height: cardHeight,
        ...style,
      }}
      source={{
        uri: `${url}`,
      }}
      contentFit="contain"
      //  placeholder={isPortrait ? portrait_blurhash : landscape_blurhash}
      // transition={{
      //   duration: 500,
      // }}
    />
  );
};
export { ExpoImage, decode, moment };
