import React from 'react';
import {
  Button,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ForgotPasswordScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSendingVerificationCode, setIsSendingVerificationCode] =
    React.useState(false);
  const [otpCode, setOtpCode] = React.useState('');
  const [otpErrorMessage, setOtpErrorMessage] = React.useState('');
  const [showVerifyCodeForm, setShowVerifyCodeForm] = React.useState(false);
  const [statusMessage, setStatusMessage] = React.useState('');
  const validateOTPCodeForm = () => {
    setErrorMessage('');
    if (email === '') {
      setErrorMessage('Please enter your email');
      return false;
    }

    if (validEmail(email) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    if (otpCode === '') {
      setErrorMessage('Please Enter your OTP Code');
    }

    if (!/^[0-9]{6}$/.test(otpCode)) {
      setErrorMessage('Invalid OTP Code');
    }

    return true;
  };

  const validEmail = email => {
    if (email === '') return true;

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    if (email === '') {
      setErrorMessage('Please enter your email');
      return false;
    }

    if (validEmail(email) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    return true;
  };

  const submitButtonLabel = () => {
    return showVerifyCodeForm ? 'Send Code Again' : 'Send Verification Code';
  };
  const newXANOAPIGetOTPPOST = NewXANOAPIApi.useGetOTPPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'ForgotPasswordScreen',
        undefined
      );
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
        },
        dimensions.width
      )}
    >
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 8'
        ].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
            'ResponsiveKeyboardAwareScrollView 8'
          ].style,
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: '20%', justifyContent: 'center' },
            dimensions.width
          )}
        >
          {/* Heading */}
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['Custom Color_2'],
                fontFamily: [
                  {
                    minWidth: Breakpoints.Laptop,
                    value: 'SpaceGrotesk_600SemiBold',
                  },
                  {
                    minWidth: Breakpoints.Mobile,
                    value: 'SpaceGrotesk_600SemiBold',
                  },
                ],
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 40 },
                  { minWidth: Breakpoints.Mobile, value: 33 },
                ],
              },
              dimensions.width
            )}
          >
            {'Forgot Password'}
          </Text>
        </View>
        {/* Form */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: [
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors.background.brand,
                },
                {
                  minWidth: Breakpoints.Desktop,
                  value: theme.colors.background.brand,
                },
              ],
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              flex: 1,
              overflow: 'hidden',
              paddingBottom: 20,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
            },
            dimensions.width
          )}
        >
          {/* Email */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Label */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['Custom Color'],
                  fontFamily: [
                    {
                      minWidth: Breakpoints.Mobile,
                      value: 'SpaceGrotesk_400Regular',
                    },
                    {
                      minWidth: Breakpoints.Laptop,
                      value: 'SpaceGrotesk_400Regular',
                    },
                  ],
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 14 },
                  ],
                },
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            {/* Email */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEmailValue => {
                try {
                  setEmail(newEmailValue?.toLowerCase());
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              editable={true}
              keyboardType={'email-address'}
              placeholder={'Enter email address'}
              placeholderTextColor={palettes.App['Border Color']}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  borderLeftWidth: 1,
                  borderRadius: 10,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 16 },
                  ],
                  height: 48,
                  marginTop: 10,
                  paddingBottom: 8,
                  paddingLeft: 15,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              textContentType={'emailAddress'}
              value={email}
            />
            {/* emailErrorMessage */}
            <>
              {!(validEmail(email) === false) ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.background.danger,
                      fontFamily: {
                        minWidth: Breakpoints.Laptop,
                        value: 'SpaceGrotesk_400Regular',
                      },
                      fontSize: { minWidth: Breakpoints.Desktop, value: 14 },
                    },
                    dimensions.width
                  )}
                >
                  {'Invalid Email Address'}
                </Text>
              )}
            </>
          </View>
          {/* GetCodeButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  if (validateForm() === false) {
                    return;
                  }
                  setStatusMessage('');
                  setErrorMessage('');
                  setIsSendingVerificationCode(true);
                  const otpResult = (
                    await newXANOAPIGetOTPPOST.mutateAsync({ email: email })
                  )?.json;
                  setIsSendingVerificationCode(false);
                  const xanoErrorMessage = otpResult?.message;
                  setStatusMessage(otpResult?.status);
                  setErrorMessage(xanoErrorMessage);
                  if (xanoErrorMessage !== undefined) {
                    return;
                  }
                  setShowVerifyCodeForm(true);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            disabled={isSendingVerificationCode}
            loading={isSendingVerificationCode}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.branding.primary,
                borderRadius: 8,
                fontFamily: 'SpaceGrotesk_600SemiBold',
                fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                height: 50,
                marginTop: 25,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={`${submitButtonLabel()}`}
          />
          {/* ErrorMessage */}
          <>
            {!errorMessage ? null : (
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors.background.danger,
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'SpaceGrotesk_400Regular',
                    },
                    fontSize: { minWidth: Breakpoints.Desktop, value: 14 },
                  },
                  dimensions.width
                )}
              >
                {errorMessage}
              </Text>
            )}
          </>
          {/* SuccessMessage */}
          <>
            {!statusMessage ? null : (
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['App Green'],
                    fontFamily: {
                      minWidth: Breakpoints.Laptop,
                      value: 'SpaceGrotesk_400Regular',
                    },
                    fontSize: { minWidth: Breakpoints.Desktop, value: 14 },
                  },
                  dimensions.width
                )}
              >
                {statusMessage}
              </Text>
            )}
          </>
          {/* VerifyCode */}
          <>
            {!showVerifyCodeForm ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors.background.brand,
                    marginTop: 20,
                    overflow: 'hidden',
                    paddingBottom: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Code */}
                <View>
                  {/* Verification Code */}
                  <Text
                    accessible={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'SpaceGrotesk_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 14 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    {'Verification Code'}
                  </Text>
                  {/* Code */}
                  <TextInput
                    autoCapitalize={'none'}
                    autoCorrect={true}
                    changeTextDelay={500}
                    onChangeText={newCodeValue => {
                      try {
                        setOtpCode(newCodeValue);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    webShowOutline={true}
                    editable={true}
                    keyboardType={'numeric'}
                    placeholder={'Enter OTP Code'}
                    placeholderTextColor={palettes.App['Border Color']}
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 1,
                        borderColor: palettes.App['Border Color'],
                        borderLeftWidth: 1,
                        borderRadius: 10,
                        borderRightWidth: 1,
                        borderTopWidth: 1,
                        color: palettes.App['Custom Color'],
                        fontFamily: 'SpaceGrotesk_400Regular',
                        fontSize: [
                          { minWidth: Breakpoints.Mobile, value: 12 },
                          { minWidth: Breakpoints.Desktop, value: 16 },
                        ],
                        height: 48,
                        marginTop: 10,
                        paddingBottom: 8,
                        paddingLeft: 15,
                        paddingRight: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                    value={otpCode}
                  />
                </View>
                {/* VerifyCodeButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        if (validateOTPCodeForm() === false) {
                          return;
                        }
                        const verifyOTPResult = (
                          await NewXANOAPIApi.verifyOTPGET(Constants, {
                            code: otpCode,
                            email: email,
                          })
                        )?.json;
                        if (!verifyOTPResult?.auth_token) {
                          return;
                        }
                        setGlobalVariableValue({
                          key: 'AUTH_TOKEN',
                          value: verifyOTPResult?.auth_token,
                        });
                        navigation.navigate('ForgotPasswordUpdateScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    {
                      backgroundColor: theme.colors.branding.primary,
                      borderRadius: 8,
                      fontFamily: 'SpaceGrotesk_600SemiBold',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                      height: 50,
                      marginTop: 25,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                  title={'Verify Code'}
                />
              </View>
            )}
          </>
          {/* Already have an acount */}
          <View
            {...GlobalStyles.ViewStyles(theme)['AHA'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ViewStyles(theme)['AHA'].style, {
                backgroundColor: {
                  minWidth: Breakpoints.Desktop,
                  value: theme.colors.background.brand,
                },
              }),
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Desktop, value: 14 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                  ],
                },
                dimensions.width
              )}
            >
              {'Already have an account?'}
            </Text>

            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('LoginScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ marginLeft: 8 }, dimensions.width)}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['Social Orange'],
                    fontFamily: 'SpaceGrotesk_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 14 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {'Log in'}
              </Text>
            </Touchable>
          </View>
        </View>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(ForgotPasswordScreen);
