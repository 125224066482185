import React, { useState, useEffect } from 'react';
import {
  Configure,
  Index,
  connectAutoComplete,
} from 'react-instantsearch-core';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';
// import Icon from 'react-native-vector-icons/Feather';
import { Icon } from '@draftbit/ui';
import palettes from '../themes/palettes';

import { Highlight } from './Highlight';

export function Suggestions({
  indexName,
  searchQuery,
  onSelect,
  onSubItemPress,
  setItemsCount,
  show,
}) {
  return (
    <View
      style={{
        ...styles.overlay,
        display: show ? 'flex' : 'none',
      }}
    >
      <Index indexName={indexName}>
        <Configure hitsPerPage={5} />
        <View style={styles.list}>
          <Text style={styles.listTitle}>Suggestions</Text>
          <AutoCompleteList
            onSelect={onSelect}
            onSubItemPress={onSubItemPress}
            searchQuery={searchQuery}
            setItemsCount={setItemsCount}
            indexName={indexName}
          />
        </View>
      </Index>
    </View>
  );
}
export const AutoCompleteList = connectAutoComplete(props => {
  const {
    hits,
    currentRefinement,
    refine,
    searchQuery,
    setItemsCount,
    indexName,
    onSelect,
    onSubItemPress,
  } = props;

  const suggestionHits = hits.find(h => h.index === indexName);
  let mostPopularHitIndex = getMostPopular(suggestionHits?.hits || []);

  React.useEffect(() => {
    if (searchQuery !== currentRefinement && refine) {
      refine(searchQuery);
    }
  }, [searchQuery, currentRefinement, refine]);

  React.useEffect(() => {
    setItemsCount?.(suggestionHits?.hits?.length);
  }, [suggestionHits?.hits]);

  return (
    <FlatList
      data={suggestionHits?.hits}
      keyExtractor={item => item.objectID}
      renderItem={({ item }) => (
        <Suggestion
          hit={item}
          onPress={onSelect}
          onSubItemPress={onSubItemPress}
          mostPopularHitIndex={mostPopularHitIndex}
        />
      )}
      keyboardShouldPersistTaps="always"
    ></FlatList>
  );
});

function Suggestion({ hit, mostPopularHitIndex, onPress, onSubItemPress }) {
  const [subList, setSubList] = useState([]);

  useEffect(() => {
    if (hit.objectID === mostPopularHitIndex) {
      const matches = hit?.tags?.facets?.exact_matches || {};
      const list = [];
      //if hit.objectID equal to the value in match, add that match to the sublist
      for (let facet of Object.keys(matches)) {
        const facetObj = matches[facet];

        if (facetObj?.[0].value.toLowerCase() === hit.objectID.toLowerCase()) {
          list.push({ facet, value: matches[facet][0].value });
        }
      }
      setSubList(list);
    }
  }, [hit.objectID === mostPopularHitIndex]);

  return (
    <>
      <Pressable
        style={({ pressed }) => [styles.item, pressed && styles.itemPressed]}
        onPress={() => onPress(hit.query)}
      >
        <Icon
          size={24}
          color={palettes.App['Custom Color_2']}
          name={'Feather/search'}
          style={{ marginRight: 9 }}
        />
        <Highlight
          hit={hit}
          attribute="query"
          highlightedStyle={styles.highlighted}
          nonHighlightedStyle={styles.nonHighlighted}
        />
      </Pressable>

      <FlatList
        data={subList}
        keyExtractor={item => item.facet}
        renderItem={({ item }) => (
          <Pressable
            style={({ pressed }) => [
              styles.item,
              pressed && styles.itemPressed,
            ]}
            onPress={() => onSubItemPress(item)}
          >
            <View style={styles.subItem}>
              <Icon
                name={'Feather/corner-down-right'}
                size={13}
                color={palettes.App['Custom Color_2']}
                style={{ marginRight: 9 }}
              />

              <Text style={{ color: '#fff' }}>
                in{' '}
                <Text style={{ fontWeight: 'bold' }}>
                  {facetTranslate(item.facet)}
                </Text>
              </Text>
            </View>
          </Pressable>
        )}
        keyboardShouldPersistTaps="always"
      ></FlatList>
    </>
  );
}

function getMostPopular(hits) {
  let mostPopular = hits[0];

  for (let i = 1; i < hits.length; i++) {
    if (hits[i].popularity > mostPopular.popularity) {
      mostPopular = hits[i];
    }
  }

  return mostPopular?.objectID || '';
}

function facetTranslate(facet) {
  switch (facet) {
    case '_tag?retail_details.retailer':
      return 'Retailers';
    case '_tag_content_details.actor':
      return 'Actors';
    case '_tag_content_details.character':
      return 'Characters';
    case '_tag_content_details.genre':
      return 'Genres';
    case '_tag_content_details.network':
      return 'Networks';
    case '_tag_retail_details.brand':
      return 'Brands';
    case '_tag_retail_details.product_name':
      return 'Products';
    case 'tag_name':
      return 'Tags';
    case '_tag_content_details.series_name':
      return 'Series';

    default:
      return 'Others';
  }
}

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    left: 4,
    right: 4,
    top: 0,
    backgroundColor: '#000000aa',
    zIndex: 1,
  },
  list: {
    backgroundColor: 'rgba(37, 37, 49, 1)',
    padding: 9,
    borderRadius: 8,
  },
  listTitle: {
    fontSize: 18,
    padding: 9,
    paddingBottom: 9,
    color: '#fff',
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 18,
    paddingLeft: 9,
    borderRadius: 8,
  },
  itemIcon: {
    marginRight: 9,
    color: '#fff',
  },
  itemPressed: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },

  subItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 25,
  },
  highlighted: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: 'rgba(74, 74, 101, 0.5)',
  },
  nonHighlighted: {
    fontWeight: 'normal',
    color: 'white',
  },
});
