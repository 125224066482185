import React from 'react';
import { Icon, Touchable, useTheme } from '@draftbit/ui';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { systemWeights } from 'react-native-typography';
import LinkingConfiguration from './LinkingConfiguration';
import CustomNavBarBlock from './components/CustomNavBarBlock';
import * as GlobalVariables from './config/GlobalVariableContext';
import formatGoToClickedTagPageScreen from './global-functions/formatGoToClickedTagPageScreen';
import formatGoToHomepageParams from './global-functions/formatGoToHomepageParams';
import formatGoToTaggerProfileScreen from './global-functions/formatGoToTaggerProfileScreen';
import navigateToCompleteOnboarding from './global-functions/navigateToCompleteOnboarding';
import showTour from './global-functions/showTour';
import ClickedTagPageScreen from './screens/ClickedTagPageScreen';
import DiscoverScreen from './screens/DiscoverScreen';
import EditProfileScreen from './screens/EditProfileScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import ForgotPasswordUpdateScreen from './screens/ForgotPasswordUpdateScreen';
import HomepageScreen from './screens/HomepageScreen';
import LoginScreen from './screens/LoginScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ProfileScreen from './screens/ProfileScreen';
import SignUpScreen from './screens/SignUpScreen';
import TagCommentPageScreen from './screens/TagCommentPageScreen';
import TagUploadScreen from './screens/TagUploadScreen';
import TaggerProfileScreen from './screens/TaggerProfileScreen';
import TermsAndConditionsScreen from './screens/TermsAndConditionsScreen';
import UnsubscribeScreen from './screens/UnsubscribeScreen';
import UnverifiedTagPreviewScreen from './screens/UnverifiedTagPreviewScreen';
import WelcomeScreen from './screens/WelcomeScreen';
import palettes from './themes/palettes';
import Breakpoints from './utils/Breakpoints';
import parseBoolean from './utils/parseBoolean';
import useWindowDimensions from './utils/useWindowDimensions';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();
const Drawer = createDrawerNavigator();

export const routingInstrumentation = new Sentry.ReactNavigationInstrumentation(
  {
    enableTimeToInitialDisplay: true,
  }
);

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function DefaultDrawerIcon({ tintColor, navigation }) {
  return (
    <Touchable
      onPress={() => navigation.toggleDrawer()}
      style={[styles.headerContainer, styles.headerContainerLeft]}
    >
      <Icon
        name="EvilIcons/navicon"
        size={27}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </Touchable>
  );
}

function HomeStackNavgiator({ navigation }) {
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', e => {
      e.preventDefault();

      navigation.navigate('BottomTabNavigator', {
        screen: 'HomeStackNavgiator',
        params: { screen: 'HomepageScreen' },
      });
    });

    return unsubscribe;
  }, [navigation]);

  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      initialRouteName="HomepageScreen"
      presentation="card"
      tabPressToInitialScreen={true}
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerMode: 'none',
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="ClickedTagPageScreen"
        component={ClickedTagPageScreen}
        options={({ navigation }) => ({
          title: 'Montage Tag',
        })}
      />
      <Stack.Screen
        name="TagCommentPageScreen"
        component={TagCommentPageScreen}
        options={({ navigation }) => ({
          title: 'Tagger',
        })}
      />
      <Stack.Screen
        name="TaggerProfileScreen"
        component={TaggerProfileScreen}
        options={({ navigation }) => ({
          title: 'Tagger',
        })}
      />
      <Stack.Screen
        name="UnverifiedTagPreviewScreen"
        component={UnverifiedTagPreviewScreen}
        options={({ navigation }) => ({
          title: 'Tag Preview',
        })}
      />
      <Stack.Screen
        name="HomepageScreen"
        component={HomepageScreen}
        options={({ navigation }) => ({
          title: 'Montage',
        })}
      />
    </Stack.Navigator>
  );
}

function ProfileStackNavigator({ navigation }) {
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('tabPress', e => {
      e.preventDefault();

      navigation.navigate('BottomTabNavigator', {
        screen: 'ProfileStackNavigator',
        params: { screen: 'ProfileScreen' },
      });
    });

    return unsubscribe;
  }, [navigation]);

  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  return (
    <Stack.Navigator
      initialRouteName="ProfileScreen"
      tabPressToInitialScreen={true}
      screenOptions={({ navigation }) => ({
        cardStyle: { flex: 1 },
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Profile',
        })}
      />
      <Stack.Screen
        name="EditProfileScreen"
        component={EditProfileScreen}
        options={({ navigation }) => ({
          title: 'Profile Settings',
        })}
      />
    </Stack.Navigator>
  );
}

function BottomTabNavigator() {
  const theme = useTheme();

  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  const safeAreaInsets = useSafeAreaInsets();

  const tabBarOrDrawerIcons = {
    DiscoverScreen: 'Entypo/home',
    HomeStackNavgiator: 'Ionicons/search',
    ProfileStackNavigator: 'Ionicons/person',
    TagUploadScreen: 'Ionicons/pricetag',
  };

  return (
    <Tab.Navigator
      tabBar={({ state, descriptors }) => {
        const mapRoute = route => {
          const descriptor = descriptors[route.key];
          return {
            name: route.name,
            label: descriptor.options.tabBarLabel ?? route.name,
            icon: tabBarOrDrawerIcons[route.name] ?? '',
          };
        };
        const routes = state.routes.map(mapRoute);
        const currentRoute = mapRoute(
          state.routes.find((_, index) => index === state.index)
        );
        return (
          <View style={{ marginBottom: safeAreaInsets.bottom }}>
            <CustomNavBarBlock routes={routes} currentRoute={currentRoute} />
          </View>
        );
      }}
      backBehavior="history"
      screenOptions={({ navigation }) => ({
        headerShown: false,
        tabBarLabelPosition: 'below-icon',
        tabBarLabelStyle: {
          fontFamily: 'SpaceGrotesk_400Regular',
          fontSize: 16,
          letterSpacing: 1,
          lineHeight: 16,
        },
        tabBarShowLabel: false,
        tabBarStyle: {
          backgroundColor: palettes.App.BackgroundPurple,
          borderTopColor: 'transparent',
        },
      })}
    >
      <Tab.Screen
        name="DiscoverScreen"
        component={DiscoverScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Entypo/home"
              size={25}
              color={focused ? theme.colors.branding.primary : color}
            />
          ),
          tabBarLabel: 'Discover',
          title: 'Discover',
        })}
      />
      <Tab.Screen
        name="HomeStackNavgiator"
        component={HomeStackNavgiator}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Ionicons/search"
              size={25}
              color={focused ? theme.colors.branding.primary : color}
            />
          ),
          tabBarLabel: 'Search',
          title: 'HomeStackNavgiator',
        })}
      />
      <Tab.Screen
        name="ProfileStackNavigator"
        component={ProfileStackNavigator}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Ionicons/person"
              size={25}
              color={focused ? theme.colors.branding.primary : color}
            />
          ),
          tabBarLabel: 'Profile',
          title: 'ProfileStackNavigator',
        })}
      />
      <Tab.Screen
        name="TagUploadScreen"
        component={TagUploadScreen}
        options={({ navigation }) => ({
          tabBarIcon: ({ focused, color }) => (
            <Icon
              name="Ionicons/pricetag"
              size={25}
              color={focused ? theme.colors.branding.primary : color}
            />
          ),
          tabBarLabel: 'Upload',
          title: 'Tag Upload',
        })}
      />
    </Tab.Navigator>
  );
}

export default function RootAppNavigator() {
  const theme = useTheme();
  const navigationRef = React.useRef();
  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#FFFFFF',
        },
      }}
      linking={LinkingConfiguration}
      ref={navigationRef}
      onReady={() =>
        routingInstrumentation.registerNavigationContainer(navigationRef)
      }
    >
      <Stack.Navigator
        initialRouteName="BottomTabNavigator"
        presentation="card"
        screenOptions={({ navigation }) => ({
          cardStyle: {
            flex: 1,
            backgroundColor: palettes.App.BackgroundPurple,
          },
          headerBackImage:
            Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
          headerMode: 'none',
          headerShown: false,
          headerTitleStyle: theme.typography.custom14,
        })}
      >
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={({ navigation }) => ({
            title: 'Welcome',
          })}
        />
        <Stack.Screen
          name="WelcomeScreen"
          component={WelcomeScreen}
          options={({ navigation }) => ({
            title: 'Welcome',
          })}
        />
        <Stack.Screen
          name="ForgotPasswordScreen"
          component={ForgotPasswordScreen}
          options={({ navigation }) => ({
            title: 'Password',
          })}
        />
        <Stack.Screen
          name="ForgotPasswordUpdateScreen"
          component={ForgotPasswordUpdateScreen}
          options={({ navigation }) => ({
            title: 'Password',
          })}
        />
        <Stack.Screen
          name="TermsAndConditionsScreen"
          component={TermsAndConditionsScreen}
          options={({ navigation }) => ({
            title: 'Terms And Conditions',
          })}
        />
        <Stack.Screen
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
          options={({ navigation }) => ({
            title: 'Privacy Policy',
          })}
        />
        <Stack.Screen
          name="SignUpScreen"
          component={SignUpScreen}
          options={({ navigation }) => ({
            gestureEnabled: false,
            title: 'Welcome',
          })}
        />
        <Stack.Screen
          name="UnsubscribeScreen"
          component={UnsubscribeScreen}
          options={({ navigation }) => ({
            title: 'Unsubscribe',
          })}
        />
        <Stack.Screen
          name="BottomTabNavigator"
          component={BottomTabNavigator}
          options={({ navigation }) => ({
            title: 'Bottom Tab Navigator',
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
});
