import React from 'react';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { ActivityIndicator, Image, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AuthComponents from '../custom-files/AuthComponents';
import * as CustomCode from '../custom-files/CustomCode';
import initialize_isLikedByUserCache from '../global-functions/initialize_isLikedByUserCache';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import trackAction from '../global-functions/trackAction';
import trackIdentity from '../global-functions/trackIdentity';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const LoginScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [email, setEmail] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoginWithApple, setIsLoginWithApple] = React.useState(false);
  const [isLoginWithGoogle, setIsLoginWithGoogle] = React.useState(false);
  const [password, setPassword] = React.useState('');
  // returns true if form is valid
  //
  const validateForm = () => {
    if (email === '') {
      setErrorMessage('Please enter your email');
      return false;
    }
    if (password === '') {
      setErrorMessage('Please enter your password');
      return false;
    }

    if (validEmail(email) === false) {
      setErrorMessage('Invalid e-mail address');
      return false;
    }

    return true;
  };

  const validEmail = email => {
    if (email === '') return true;

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      pushToHistory(
        Variables,
        setGlobalVariableValue,
        segment,
        'LoginScreen',
        undefined
      );
      if (
        navigateToCompleteOnboarding(
          navigation,
          setGlobalVariableValue,
          Constants['USER']
        )
      ) {
        navigation.navigate('SignUpScreen', { completeOnBoarding: true });
      }
    } catch (err) {
      Sentry.captureException(err);
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignItems: [
            { minWidth: Breakpoints.BigScreen, value: 'center' },
            { minWidth: Breakpoints.Laptop, value: 'center' },
          ],
          alignSelf: [
            { minWidth: Breakpoints.Laptop, value: 'center' },
            { minWidth: Breakpoints.BigScreen, value: 'center' },
          ],
          backgroundColor: palettes.App.BackgroundPurple,
          justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
        },
        dimensions.width
      )}
    >
      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 10'
        ].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
            'ResponsiveKeyboardAwareScrollView 10'
          ].style,
          dimensions.width
        )}
      >
        {/* Header */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', height: '20%', justifyContent: 'center' },
            dimensions.width
          )}
        >
          {/* Heading */}
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['Custom Color_2'],
                fontFamily: 'SpaceGrotesk_600SemiBold',
                fontSize: [
                  { minWidth: Breakpoints.Mobile, value: 33 },
                  { minWidth: Breakpoints.Desktop, value: 40 },
                ],
              },
              dimensions.width
            )}
          >
            {'Log In'}
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
              backgroundColor: theme.colors.background.brand,
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              flex: 1,
              maxWidth: { minWidth: Breakpoints.Desktop, value: 600 },
              minWidth: { minWidth: Breakpoints.Laptop, value: 600 },
              overflow: 'hidden',
              padding: 20,
            },
            dimensions.width
          )}
        >
          {/* Email */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Label */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Desktop, value: 16 },
                    { minWidth: Breakpoints.Mobile, value: 12 },
                  ],
                },
                dimensions.width
              )}
            >
              {'Email'}
            </Text>
            {/* Email */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEmailValue => {
                try {
                  setEmail(newEmailValue?.toLowerCase());
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              allowFontScaling={false}
              editable={true}
              keyboardType={'email-address'}
              placeholder={'Enter email address'}
              placeholderTextColor={palettes.App['Border Color']}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  borderLeftWidth: 1,
                  borderRadius: 10,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 16 },
                  ],
                  height: 48,
                  marginTop: 10,
                  paddingBottom: 8,
                  paddingLeft: 15,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              textContentType={'emailAddress'}
              value={email}
            />
            {/* emailErrorMessage */}
            <>
              {!(validEmail(email) === false) ? null : (
                <Text
                  accessible={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors.background.danger,
                      fontFamily: {
                        minWidth: Breakpoints.Desktop,
                        value: 'SpaceGrotesk_400Regular',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {'Invalid Email Address'}
                </Text>
              )}
            </>
          </View>
          {/* Password */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
          >
            {/* Label */}
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 16 },
                  ],
                },
                dimensions.width
              )}
            >
              {'Password'}
            </Text>
            {/* Password */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newPasswordValue => {
                try {
                  setPassword(newPasswordValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              editable={true}
              placeholder={'********'}
              placeholderTextColor={palettes.App['Border Color']}
              secureTextEntry={true}
              style={StyleSheet.applyWidth(
                {
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  borderLeftWidth: 1,
                  borderRadius: 10,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 16 },
                  ],
                  height: 48,
                  marginTop: 10,
                  paddingBottom: 8,
                  paddingLeft: 15,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              textContentType={'password'}
              value={password}
            />
          </View>
          {/* Forgot Password? */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('ForgotPasswordScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['Custom Color'],
                    fontFamily: 'SpaceGrotesk_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 12 },
                      { minWidth: Breakpoints.Desktop, value: 16 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {'Forgot Password?'}
              </Text>
            </Pressable>
          </View>
          {/* LoginButton */}
          <Button
            iconPosition={'left'}
            onPress={() => {
              const handler = async () => {
                try {
                  if (validateForm() === false) {
                    return;
                  }
                  setIsLoading(true);
                  const loginResponse = (
                    await NewXANOAPIApi.loginPOST(Constants, {
                      email: email,
                      password: password,
                    })
                  )?.json;
                  setIsLoading(false);
                  const xanoErrorMessage = loginResponse?.message;
                  const xanoUser = loginResponse?.user;
                  const xanoToken = loginResponse?.auth_token;
                  setErrorMessage(xanoErrorMessage);
                  if (xanoErrorMessage) {
                    return;
                  }
                  setGlobalVariableValue({
                    key: 'AUTH_TOKEN',
                    value: xanoToken,
                  });
                  setGlobalVariableValue({
                    key: 'USER',
                    value: xanoUser,
                  });
                  trackIdentity(segment, email);
                  trackAction(Variables, segment, 'Login Completed', undefined);
                  if (!xanoUser?._profile_of_user?.handle) {
                    navigation.navigate('SignUpScreen', {
                      completeOnBoarding: true,
                    });
                  } else {
                    await initialize_isLikedByUserCache(
                      Variables,
                      setGlobalVariableValue,
                      xanoToken
                    );
                    navigation.navigate('BottomTabNavigator', {
                      screen: 'DiscoverScreen',
                    });
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              };
              handler();
            }}
            disabled={isLoading}
            loading={isLoading}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.branding.primary,
                borderRadius: 8,
                fontFamily: 'SpaceGrotesk_600SemiBold',
                fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
                height: 50,
                marginTop: 25,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Log in'}
          />
          {/* ErrorMessage */}
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors.background.danger,
                fontFamily: 'SpaceGrotesk_400Regular',
                fontSize: { minWidth: Breakpoints.Desktop, value: 16 },
              },
              dimensions.width
            )}
          >
            {errorMessage}
          </Text>
          {/* Don't have an account  */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 30,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              style={StyleSheet.applyWidth(
                {
                  color: palettes.App['Custom Color'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: [
                    { minWidth: Breakpoints.Mobile, value: 12 },
                    { minWidth: Breakpoints.Desktop, value: 16 },
                  ],
                },
                dimensions.width
              )}
            >
              {"Don't have an account?"}
            </Text>

            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('SignUpScreen');
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ marginLeft: 8 }, dimensions.width)}
            >
              <Text
                accessible={true}
                style={StyleSheet.applyWidth(
                  {
                    color: palettes.App['Social Orange'],
                    fontFamily: 'SpaceGrotesk_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Desktop, value: 16 },
                      { minWidth: Breakpoints.Mobile, value: 12 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {'Sign up'}
              </Text>
            </Touchable>
          </View>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontSize: [
                  { minWidth: Breakpoints.Desktop, value: 14 },
                  { minWidth: Breakpoints.Mobile, value: 12 },
                ],
                marginTop: 12,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {'-- or --'}
          </Text>
          {/* SoicalLogins */}
          <View
            {...GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ViewStyles(theme)['SoicalLogins 2'].style,
                { marginTop: 20 }
              ),
              dimensions.width
            )}
          >
            {/* GoogleView */}
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* GoogleAuthWrapper */}
              <Utils.CustomCodeErrorBoundary>
                <AuthComponents.GoogleLogin
                  navigation={props.navigation}
                  theme={props.theme}
                  buttonType="signup"
                  setErrorMessage={setErrorMessage}
                  setIsLoading={setIsLoginWithGoogle}
                  isLoading={isLoginWithGoogle}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        alignSelf: 'auto',
                        backgroundColor: theme.colors.background.brand,
                        borderColor: palettes.App['Border Color'],
                        borderRadius: 12,
                        borderWidth: 1,
                        flex: 1,
                        flexDirection: 'row',
                        height: { minWidth: Breakpoints.Desktop, value: 60 },
                        justifyContent: 'center',
                        maxHeight: 50,
                        paddingBottom: 8,
                        paddingTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {isLoginWithGoogle ? null : (
                        <Image
                          resizeMode={'cover'}
                          {...GlobalStyles.ImageStyles(theme)['Image'].props}
                          source={imageSource(Images['Google'])}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'].style,
                              { height: 22, width: 22 }
                            ),
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <>
                      {!isLoginWithGoogle ? null : (
                        <ActivityIndicator
                          animating={true}
                          hidesWhenStopped={true}
                          size={'small'}
                          {...GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].style,
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </View>
                </AuthComponents.GoogleLogin>
              </Utils.CustomCodeErrorBoundary>
            </View>
            {/* AppleView */}
            <>
              {!(Platform.OS === 'ios') ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 12 },
                    dimensions.width
                  )}
                >
                  {/* AppleWrapper */}
                  <Utils.CustomCodeErrorBoundary>
                    <AuthComponents.AppleWrapper
                      navigation={props.navigation}
                      theme={props.theme}
                      setIsLoading={setIsLoginWithApple}
                      isLoading={isLoginWithApple}
                      setErrorMessage={setErrorMessage}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            alignSelf: 'auto',
                            backgroundColor: theme.colors.background.brand,
                            borderColor: palettes.App['Border Color'],
                            borderRadius: 12,
                            borderWidth: 1,
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            maxHeight: 50,
                            paddingBottom: 8,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                      >
                        <>
                          {!isLoginWithApple ? null : (
                            <ActivityIndicator
                              animating={true}
                              hidesWhenStopped={true}
                              size={'small'}
                              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].style,
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                        <>
                          {isLoginWithApple ? null : (
                            <Icon size={24} name={'AntDesign/apple1'} />
                          )}
                        </>
                      </View>
                    </AuthComponents.AppleWrapper>
                  </Utils.CustomCodeErrorBoundary>
                </View>
              )}
            </>
          </View>
        </View>
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
