import React from 'react';
import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  Surface,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { BlurView } from 'expo-blur';
import { ActivityIndicator, Image, Modal, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import formatGoToClickedTagPageScreen from '../global-functions/formatGoToClickedTagPageScreen';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import toRelativeTime from '../global-functions/toRelativeTime';
import trackAction from '../global-functions/trackAction';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = { ClickedTagID: 688 };

const TagCommentPageScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [commentIdToDelete, setCommentIdToDelete] = React.useState('');
  const [commentsValue, setCommentsValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isPosting, setIsPosting] = React.useState(false);
  const [likingCommentId, setLikingCommentId] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const isNotValid = () => {
    if (!commentsValue) {
      setErrorMessage('Please enter a comment');
      return true;
    }

    // if(commentsValue.length>500) {
    //   setErrorMessage('Comment must be less than 500 characters')
    //   return true
    // }

    // if(commentsValue.length<10) {
    //   setErrorMessage('Comment must be more than 10 characters')
    //   return true
    // }
    setErrorMessage('');

    return false;
  };

  const formatTrackData = id => {
    return { id };
  };

  const goBack = (Variables, setGlobalVariableValue) => {
    let history = Variables.HISTORY || [];

    //first remove the current screen from history
    history.pop();

    //then go back to the previous screen
    const previousScreen = history.pop();
    setGlobalVariableValue({
      key: 'HISTORY',
      value: history,
    });

    if (previousScreen) {
      props?.navigation?.navigate(
        previousScreen.screenName,
        previousScreen.params
      );
    }
  };
  const newXANOAPIToggleCommentLikePOST =
    NewXANOAPIApi.useToggleCommentLikePOST();
  const newXANOAPIPostCommentPOST = NewXANOAPIApi.usePostCommentPOST();
  const newXANOAPIDeleteCommentDELETE = NewXANOAPIApi.useDeleteCommentDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'TagCommentPageScreen',
          formatGoToClickedTagPageScreen(
            props.route?.params?.ClickedTagID ?? defaultProps.ClickedTagID
          )
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      <Surface
        elevation={0}
        {...GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].props}
        style={StyleSheet.applyWidth(
          GlobalStyles.SurfaceStyles(theme)['FloatingBackButtonShadow'].style,
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              goBack(Variables, setGlobalVariableValue);
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={palettes.App['Custom Color_2']}
              name={'AntDesign/leftcircle'}
              size={40}
            />
          </View>
        </Touchable>
      </Surface>

      <SimpleStyleKeyboardAwareScrollView
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 4'
        ].props}
        enableAutomaticScroll={true}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
              'ResponsiveKeyboardAwareScrollView 4'
            ].style,
            { justifyContent: 'flex-end', paddingTop: 50 }
          ),
          dimensions.width
        )}
      >
        <NewXANOAPIApi.FetchGetCommentsOfTagsGET
          handlers={{
            onData: fetchData => {
              try {
                setLikingCommentId('');
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            },
          }}
          tag_id={
            props.route?.params?.ClickedTagID ?? defaultProps.ClickedTagID
          }
        >
          {({ loading, error, data, refetchGetCommentsOfTags }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                {/* Header */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: palettes.App.CardBorder,
                      borderRadius: 4,
                      borderWidth: 1,
                      height: 320,
                      justifyContent: 'center',
                      marginBottom: 11,
                      marginLeft: 11,
                      marginRight: 11,
                      marginTop: 11,
                      overflow: 'hidden',
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'HomeStackNavgiator',
                          params: {
                            screen: 'ClickedTagPageScreen',
                            params: {
                              tagID:
                                props.route?.params?.ClickedTagID ??
                                defaultProps.ClickedTagID,
                            },
                          },
                        });
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    activeOpacity={0.8}
                    disabledOpacity={0.8}
                    style={StyleSheet.applyWidth(
                      { height: 300, width: 335 },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'center',
                          borderRadius: 2,
                          height: 300,
                          justifyContent: 'center',
                          width: 335,
                        },
                        dimensions.width
                      )}
                    >
                      <Image
                        resizeMode={'contain'}
                        source={{
                          uri: `${
                            fetchData?._tag_photos &&
                            (fetchData?._tag_photos)[0]?.tag_photo?.url
                          }`,
                        }}
                        style={StyleSheet.applyWidth(
                          { borderRadius: 0, height: '100%', width: '100%' },
                          dimensions.width
                        )}
                      />
                    </View>
                  </Touchable>
                </View>
                {/* Body */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flex: 1,
                      justifyContent: 'flex-end',
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* CommentsCountView */}
                  <>
                    {!(fetchData?._comments_count > 0) ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* CommentCount */}
                        <Text
                          accessible={true}
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'flex-end',
                              color: palettes.App['Custom Color_2'],
                              fontFamily: 'SpaceGrotesk_400Regular',
                              paddingBottom: 7,
                              paddingTop: 7,
                              textAlign: 'center',
                            },
                            dimensions.width
                          )}
                        >
                          {fetchData?._comments_count}
                          {' comment'}
                          {/* PluralS */}
                          <>
                            {!(fetchData?._comments_count > 1) ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  dimensions.width
                                )}
                              >
                                {'s'}
                              </Text>
                            )}
                          </>
                        </Text>
                      </View>
                    )}
                  </>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: {
                          minWidth: Breakpoints.Tablet,
                          value: 'center',
                        },
                        flex: 1,
                        overflow: 'hidden',
                        paddingLeft: 3,
                        paddingRight: 3,
                      },
                      dimensions.width
                    )}
                  >
                    <SimpleStyleFlatList
                      data={fetchData?._comments_of_tags}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) =>
                        listData?.id ??
                        listData?.uuid ??
                        index?.toString() ??
                        JSON.stringify(listData)
                      }
                      keyboardShouldPersistTaps={'never'}
                      listKey={'efLXXHnf'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Commets Container */}
                            <View
                              {...GlobalStyles.ViewStyles(theme)[
                                'Commets Container'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ViewStyles(theme)[
                                    'Commets Container'
                                  ].style,
                                  {
                                    minWidth: {
                                      minWidth: Breakpoints.Tablet,
                                      value: 500,
                                    },
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* CommentsSection */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { flex: 1 },
                                  dimensions.width
                                )}
                              >
                                <Touchable
                                  onPress={() => {
                                    try {
                                      navigation.navigate('BottomTabNavigator');
                                    } catch (err) {
                                      Sentry.captureException(err);
                                      console.error(err);
                                    }
                                  }}
                                >
                                  {/* CommentHeader */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        flexDirection: 'row',
                                        flexGrow: 1,
                                        flexShrink: 0,
                                        justifyContent: 'flex-start',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* UserImage */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          borderRadius: 0,
                                          marginLeft: 5,
                                          marginRight: 7,
                                          marginTop: 5,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <Touchable
                                        activeOpacity={0.8}
                                        disabledOpacity={0.8}
                                      >
                                        <>
                                          {!listData?._commenter?.profile_photo
                                            ?.url ? null : (
                                            <Image
                                              resizeMode={'cover'}
                                              source={{
                                                uri: `${listData?._commenter?.profile_photo?.url}`,
                                              }}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  borderRadius: 11,
                                                  height: 20,
                                                  width: 20,
                                                },
                                                dimensions.width
                                              )}
                                            />
                                          )}
                                        </>
                                        <>
                                          {listData?._commenter?.profile_photo
                                            ?.url ? null : (
                                            <Icon
                                              color={
                                                theme.colors.background.brand
                                              }
                                              name={'FontAwesome/user-circle'}
                                              size={20}
                                            />
                                          )}
                                        </>
                                      </Touchable>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        { flex: 1, marginBottom: 5 },
                                        dimensions.width
                                      )}
                                    >
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'baseline',
                                            alignSelf: 'auto',
                                            flexDirection: 'row',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Name */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentName'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentName'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {listData?._commenter?.handle}
                                        </Text>
                                        {/* Timeago */}
                                        <Text
                                          accessible={true}
                                          {...GlobalStyles.TextStyles(theme)[
                                            'ResponsiveCommentTimeAgo'
                                          ].props}
                                          style={StyleSheet.applyWidth(
                                            GlobalStyles.TextStyles(theme)[
                                              'ResponsiveCommentTimeAgo'
                                            ].style,
                                            dimensions.width
                                          )}
                                        >
                                          {'  '}
                                          {toRelativeTime(listData?.created_at)}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                  {/* CommentBody */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        marginLeft: 34,
                                        marginRight: 14,
                                        marginTop: 7,
                                        paddingBottom: 7,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)[
                                        'ResponsiveCommentBody'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        GlobalStyles.TextStyles(theme)[
                                          'ResponsiveCommentBody'
                                        ].style,
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.comment}
                                    </Text>
                                  </View>
                                </Touchable>
                              </View>
                              {/* CommentsActions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    borderColor: theme.colors.text.medium,
                                    flexDirection: 'row',
                                    marginLeft: 34,
                                    padding: 9,
                                  },
                                  dimensions.width
                                )}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-start', flex: 1 },
                                    dimensions.width
                                  )}
                                >
                                  {/* LikeButton */}
                                  <Touchable
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setLikingCommentId(listData?.id);
                                          const likeCommentResult = (
                                            await newXANOAPIToggleCommentLikePOST.mutateAsync(
                                              { comment_id: listData?.id }
                                            )
                                          )?.json;
                                          console.log(likeCommentResult);
                                        } catch (err) {
                                          Sentry.captureException(err);
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                  >
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: [
                                            {
                                              minWidth: Breakpoints.Mobile,
                                              value: 'center',
                                            },
                                            {
                                              minWidth: Breakpoints.Desktop,
                                              value: 'center',
                                            },
                                          ],
                                          flexDirection: {
                                            minWidth: Breakpoints.Desktop,
                                            value: 'column',
                                          },
                                          height: 36,
                                          justifyContent: 'center',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(
                                          likingCommentId === listData?.id
                                        ) ? null : (
                                          <ActivityIndicator
                                            animating={true}
                                            hidesWhenStopped={true}
                                            size={'small'}
                                            {...GlobalStyles.ActivityIndicatorStyles(
                                              theme
                                            )['Activity Indicator'].props}
                                            style={StyleSheet.applyWidth(
                                              GlobalStyles.ActivityIndicatorStyles(
                                                theme
                                              )['Activity Indicator'].style,
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          likingCommentId !== listData?.id
                                        ) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                height: 36,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Checkbox
                                              checkedIcon={'FontAwesome/heart'}
                                              color={
                                                palettes.App['Custom Color_2']
                                              }
                                              defaultValue={
                                                listData?._is_liked_by_user
                                              }
                                              disabled={true}
                                              size={20}
                                              uncheckedColor={
                                                palettes.App['Custom Color_2']
                                              }
                                              uncheckedIcon={'Feather/heart'}
                                            />
                                            <>
                                              {!(
                                                listData?._likes_count > 0
                                              ) ? null : (
                                                <Text
                                                  accessible={true}
                                                  {...GlobalStyles.TextStyles(
                                                    theme
                                                  )['label'].props}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['label'].style,
                                                      {
                                                        color: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value:
                                                              palettes.App[
                                                                'Custom Color_2'
                                                              ],
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value:
                                                              palettes.App[
                                                                'Custom Color_2'
                                                              ],
                                                          },
                                                        ],
                                                        fontSize: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 12,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 14,
                                                          },
                                                        ],
                                                        marginLeft: [
                                                          {
                                                            minWidth:
                                                              Breakpoints.Desktop,
                                                            value: 8,
                                                          },
                                                          {
                                                            minWidth:
                                                              Breakpoints.Mobile,
                                                            value: 12,
                                                          },
                                                        ],
                                                        textAlign: {
                                                          minWidth:
                                                            Breakpoints.Desktop,
                                                          value: 'center',
                                                        },
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {listData?._likes_count}
                                                </Text>
                                              )}
                                            </>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </Touchable>
                                </View>
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-end' },
                                    dimensions.width
                                  )}
                                >
                                  {/* DeleteArea */}
                                  <>
                                    {!(
                                      Constants['USER']?.id ===
                                      listData?._commenter?.user_id
                                    ) ? null : (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            setShowModal(true);
                                            setCommentIdToDelete(listData?.id);
                                          } catch (err) {
                                            Sentry.captureException(err);
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              alignSelf: 'center',
                                              height: 36,
                                              justifyContent: 'center',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Icon
                                            color={palettes.App['Border Color']}
                                            name={'Ionicons/trash-outline'}
                                            size={20}
                                          />
                                        </View>
                                      </Touchable>
                                    )}
                                  </>
                                </View>
                              </View>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={true}
                      showsVerticalScrollIndicator={true}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: 11,
                        marginRight: 11,
                        marginTop: 9,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* ErrorMessage */}
                    <>
                      {!errorMessage ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                color: theme.colors.background.danger,
                                textAlign: 'center',
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {errorMessage}
                        </Text>
                      )}
                    </>
                    {/* Message Tray */}
                    <View
                      {...GlobalStyles.ViewStyles(theme)[
                        'ResponsiveSearchBoxView'
                      ].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ViewStyles(theme)[
                            'ResponsiveSearchBoxView'
                          ].style,
                          { marginRight: 20 }
                        ),
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          },
                          dimensions.width
                        )}
                      >
                        {/* Comments */}
                        <TextInput
                          autoCapitalize={'none'}
                          autoCorrect={true}
                          changeTextDelay={500}
                          onChangeText={newCommentsValue => {
                            try {
                              setCommentsValue(newCommentsValue);
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          }}
                          onSubmitEditing={() => {
                            const handler = async () => {
                              try {
                                if (isNotValid()) {
                                  return;
                                }
                                setIsPosting(true);
                                const response = (
                                  await newXANOAPIPostCommentPOST.mutateAsync({
                                    comment: commentsValue,
                                    tags_id:
                                      props.route?.params?.ClickedTagID ??
                                      defaultProps.ClickedTagID,
                                  })
                                )?.json;
                                setIsPosting(false);
                                setCommentsValue('');
                                (
                                  await NewXANOAPIApi.getCommentsOfTagsGET(
                                    Constants,
                                    {
                                      tag_id:
                                        props.route?.params?.ClickedTagID ??
                                        defaultProps.ClickedTagID,
                                    }
                                  )
                                )?.json;
                                trackAction(
                                  Variables,
                                  segment,
                                  'Tag Commented',
                                  fetchData?.id?.format
                                );
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          webShowOutline={true}
                          editable={true}
                          placeholder={'Enter your comment here'}
                          placeholderTextColor={palettes.App['Custom Color_2']}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 8,
                              color: palettes.App['Custom Color_2'],
                              fontFamily: 'SpaceGrotesk_500Medium',
                              fontSize: 15,
                              paddingBottom: 8,
                              paddingLeft: 8,
                              paddingRight: 8,
                              paddingTop: 8,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                          value={commentsValue}
                        />
                      </View>
                      {/* Post Comment Button */}
                      <Touchable
                        onPress={() => {
                          const handler = async () => {
                            try {
                              if (isNotValid()) {
                                return;
                              }
                              setIsPosting(true);
                              const response = (
                                await newXANOAPIPostCommentPOST.mutateAsync({
                                  comment: commentsValue,
                                  tags_id:
                                    props.route?.params?.ClickedTagID ??
                                    defaultProps.ClickedTagID,
                                })
                              )?.json;
                              setIsPosting(false);
                              setCommentsValue('');
                              (
                                await NewXANOAPIApi.getCommentsOfTagsGET(
                                  Constants,
                                  {
                                    tag_id:
                                      props.route?.params?.ClickedTagID ??
                                      defaultProps.ClickedTagID,
                                  }
                                )
                              )?.json;
                              trackAction(
                                Variables,
                                segment,
                                'Tag Commented',
                                formatTrackData(fetchData?.id)
                              );
                            } catch (err) {
                              Sentry.captureException(err);
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        disabled={isPosting}
                      >
                        <>
                          {!!isPosting ? null : (
                            <Icon
                              size={24}
                              color={palettes.App['Custom Color_2']}
                              name={'FontAwesome/send-o'}
                            />
                          )}
                        </>
                        <>
                          {!isPosting ? null : (
                            <ActivityIndicator
                              animating={true}
                              hidesWhenStopped={true}
                              size={'small'}
                              {...GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].props}
                              style={StyleSheet.applyWidth(
                                GlobalStyles.ActivityIndicatorStyles(theme)[
                                  'Activity Indicator'
                                ].style,
                                dimensions.width
                              )}
                            />
                          )}
                        </>
                      </Touchable>
                    </View>
                  </View>
                </View>
              </>
            );
          }}
        </NewXANOAPIApi.FetchGetCommentsOfTagsGET>
      </SimpleStyleKeyboardAwareScrollView>
      {/* ConfirmDeleteModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={showModal}
      >
        <BlurView
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={{
            height: dimensions.height,
            width: dimensions.width,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: '"rgba(252, 252, 252, 0.8)"',
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  flexDirection: 'row',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'auto',
                      flex: 1,
                      fontFamily: 'SpaceGrotesk_700Bold',
                      fontSize: 16,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Are you sure? '}
              </Text>
              {/* CloseModalButton */}
              <IconButton
                onPress={() => {
                  try {
                    setShowModal(false);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                size={32}
                color={theme.colors.text.strong}
                icon={'AntDesign/closecircleo'}
                style={StyleSheet.applyWidth(
                  { marginLeft: 16 },
                  dimensions.width
                )}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'You are about to delete this comment. You can not undo this action. Do you want to proceed? '
                }
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowModal(false);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: theme.colors.branding.primary,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        const deleteResult = (
                          await newXANOAPIDeleteCommentDELETE.mutateAsync({
                            comments_id: commentIdToDelete,
                          })
                        )?.json;
                        setIsDeleting(false);
                        console.log(deleteResult);
                        setShowModal(false);
                        undefined;
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App['Custom Color_11'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(TagCommentPageScreen);
