import palettes from '../themes/palettes';
import React, { useEffect, useState, useRef } from 'react';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import { useDebounce } from './Hooks';
import {
  View,
  Animated,
  TextInput,
  useWindowDimensions,
  Image,
} from 'react-native';

export const StrokeText = props => {
  return (
    <Text
      {...props}
      style={{ ...props.style, textShadowOffset: { width: -2, height: -2 } }}
    />
  );
};

export const HandleTextInput = ({
  theme,
  style = {
    borderBottomWidth: 1,
    borderColor: palettes.App['CardBorder'],
    borderLeftWidth: 1,
    borderRadius: 10,
    borderRightWidth: 1,
    borderTopWidth: 1,
    color: palettes.App['Custom Color'],
    fontFamily: 'SpaceGrotesk_400Regular',
    fontSize: 12,
    height: 48,
    marginTop: 10,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 8,
    paddingTop: 8,
  },
  disabled,
  uniqueNameValue,
  setUniqueNameValue,
  setHandleErrorMessage,
}) => {
  const Constants = GlobalVariables.useValues();
  const { width: windowWidth } = useWindowDimensions();
  const isBigScreen = Breakpoints.Desktop <= windowWidth;

  const attachPrefix = str => {
    if (str.length > 0 && str[0] !== '@') {
      return '@' + str;
    }

    return str;
  };

  const debounceUniqueName = useDebounce(uniqueNameValue, 500);

  useEffect(() => {
    const handler = async () => {
      try {
        setHandleErrorMessage(null);

        if (uniqueNameValue === '@') {
          setHandleErrorMessage('Please enter a valid handle');
          return;
        }

        const response = await fetch(
          `https://x8x4-ck6z-yoac.n7.xano.io/api:pDqLJK1x/unique_handle_check?handle=${uniqueNameValue}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        const isUniqueName = await response.json();

        if (
          !isUniqueName &&
          uniqueNameValue !== Constants.user?._profile_of_user?.handle
        ) {
          setHandleErrorMessage('Handle is already taken');
        }
      } catch (err) {
        console.error(err);
      }
    };

    if (debounceUniqueName && !disabled) handler();
  }, [debounceUniqueName, disabled]);

  return (
    <TextInput
      onChangeText={newUniqueNameValue => {
        setUniqueNameValue(attachPrefix(newUniqueNameValue));

        if (!newUniqueNameValue || newUniqueNameValue === '') {
          setHandleErrorMessage(null);
        }
      }}
      style={{ ...style, fontSize: isBigScreen ? 16 : 14 }}
      value={uniqueNameValue}
      placeholder={'Enter a handle beginning with @'}
      editable={true}
      placeholderTextColor={palettes.App['CardBorder']}
      textContentType={'username'}
      autoCapitalize="none"
      disabled={disabled}
    />
  );
};

export const FadeCarousel = ({
  images,
  start,
  stillDuration,
  fadeDuration,
  containerStyle,
  elementStyles,
  height = 100,
  width = 100,
  delay = 0,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const animationOut = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    setCurrentIndex(images.length - 1);

    if (start) {
      waitNSeconds((stillDuration + delay) / 1000).then(() =>
        fadeOutAnimation()
      );
    }
  }, [start]);

  const fadeOutAnimation = () => {
    animationOut.setValue(1);
    Animated.timing(animationOut, {
      toValue: 0,
      duration: fadeDuration,
      useNativeDriver: true,
    }).start(async () => {
      await waitNSeconds(stillDuration / 1000);

      setCurrentIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : images.length - 1
      );

      if (start) fadeOutAnimation();
    });
  };

  const waitNSeconds = seconds =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, seconds * 1000);
    });

  let eleStyles = elementStyles
    ? [elementStyles, { position: 'absolute' }]
    : { position: 'absolute', height: 'auto%', width: 'auto%' }; //, height: 'auto', width: 'auto'

  return (
    <View style={[{ zIndex: 0, width: '100%' }, containerStyle]}>
      {images.map((image, i) => {
        let styleA = { opacity: 1 };
        let zIndex = 0;

        if (i === currentIndex) {
          zIndex = 2;
          styleA = { opacity: animationOut };
        }

        if (
          i === currentIndex - 1 ||
          (currentIndex === 0 && i === images.length - 1)
        ) {
          zIndex = 1;
        }

        styleA.zIndex = zIndex;

        return (
          <Animated.View
            key={i}
            style={[styleA, eleStyles, { height, width: '100%' }]}
          >
            <Image
              source={{
                uri: image?.replace('f_webp/', 'f_webp/ar_20:13,c_crop/'),
              }}
              style={{ height: '100%', width: '100%' }}
              resizeMode="cover"
            />
          </Animated.View>
        );
      })}
    </View>
  );
};
