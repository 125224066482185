import { Platform } from 'react-native';

const trackIdentity = (segment, email) => {
  try {
    segment.identify(email, {
      platform: Platform.OS,
    });
  } catch (err) {
    console.error(err);
  }
};

export default trackIdentity;
