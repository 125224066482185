import React from 'react';
import {
  Button,
  Icon,
  KeyboardAvoidingView,
  Picker,
  ScreenContainer,
  SimpleStyleKeyboardAwareScrollView,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Image, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as TagsAPIApi from '../apis/TagsAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import useWindowDimensions from '../utils/useWindowDimensions';

const TagUploadScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [actorValue, setActorValue] = React.useState('');
  const [brandValue, setBrandValue] = React.useState('');
  const [categoryValue, setCategoryValue] = React.useState('');
  const [characterValue, setCharacterValue] = React.useState('');
  const [episodeValue, setEpisodeValue] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [genderValue, setGenderValue] = React.useState('');
  const [genreValue, setGenreValue] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [keywordsValue, setKeywordsValue] = React.useState('');
  const [networkValue, setNetworkValue] = React.useState('');
  const [priceValue, setPriceValue] = React.useState('');
  const [productLinkValue, setProductLinkValue] = React.useState('');
  const [productNameValue, setProductNameValue] = React.useState('');
  const [retailerValue, setRetailerValue] = React.useState('');
  const [seriesShowValue, setSeriesShowValue] = React.useState('');
  const [tagNameValue, setTagNameValue] = React.useState('');
  const [tagPhotoValue, setTagPhotoValue] = React.useState('');
  const verifyForm = () => {
    if (tagPhotoValue === '') {
      setErrorMessage('You must choose an image first');
      return false;
    }

    if (tagNameValue === '') {
      setErrorMessage('Tag name cannot be empty');
      return false;
    }

    if (seriesShowValue === '') {
      setErrorMessage('Series/Show cannot be empty');
      return false;
    }

    if (productNameValue === '') {
      setErrorMessage('Product name cannot be empty');
      return false;
    }

    if (productLinkValue === '') {
      setErrorMessage('Product link cannot be empty');
      return false;
    }

    return true;
  };

  // Generates blurhash string of an image   using base64 string
  const generateBlurhash = async imageString => {
    async function base64ToBlurhash(base64, width, height) {
      // const base64Data = base64.replace(/^data:image\/\w+;base64,/, '');
      // const decoded = CustomCode.decode(base64Data);
      console.log(CustomCode.ImageBase64);
      const imageData = await CustomCode?.ImageBase64?.getActualImageData(
        base64
      );

      const blurhash = await CustomCode.BlurhashEncoder(
        imageData.data,
        width,
        height,
        4,
        4
      );
      return blurhash;
    }

    const start = Date.now();
    // Assuming you have the desired width and height for the blurhash
    const width = 640;
    const height = 640;

    const blurhash = await base64ToBlurhash(imageString, width, height);

    const end = Date.now();
    console.log(blurhash, start, end, end - start);

    return blurhash;
  };

  const clearForm = () => {
    setTagNameValue('');
    setSeriesShowValue('');
    setEpisodeValue('');
    setNetworkValue('');
    setCharacterValue('');
    setActorValue('');
    setProductNameValue('');
    setProductLinkValue('');
    setBrandValue('');
    setRetailerValue('');
    setGenreValue('');
    setGenderValue('');
    setCategoryValue('');
    setPriceValue('');
    setKeywordsValue('');
    setTagPhotoValue('');
  };
  const tagsAPICreateTagPOST = TagsAPIApi.useCreateTagPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'TagUploadScreen',
          undefined
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
          justifyContent: 'flex-end',
        },
        dimensions.width
      )}
    >
      <SimpleStyleKeyboardAwareScrollView
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 2'
        ].props}
        enableAutomaticScroll={true}
        keyboardShouldPersistTaps={'always'}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
              'ResponsiveKeyboardAwareScrollView 2'
            ].style,
            {
              marginLeft: 11,
              marginRight: 11,
              maxWidth: { minWidth: Breakpoints.Laptop, value: 768 },
              paddingBottom: 16,
            }
          ),
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              borderBottomWidth: 1,
              borderColor: palettes.App.CardBorder,
              justifyContent: 'flex-end',
              marginLeft: 20,
              marginRight: 20,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            style={StyleSheet.applyWidth(
              {
                color: palettes.App['Custom Color_2'],
                fontFamily: 'SpaceGrotesk_400Regular',
                fontSize: [
                  { minWidth: Breakpoints.BigScreen, value: 18 },
                  { minWidth: Breakpoints.Desktop, value: 18 },
                  { minWidth: Breakpoints.Mobile, value: 13 },
                ],
                marginLeft: 9,
                marginRight: 9,
                marginTop: 11,
                textAlign: 'justify',
                whiteSpace: 'pre-line',
              },
              dimensions.width
            )}
          >
            {
              "Upload a new tag here. Once uploaded, Montage will validate your tag and its product link and publish validated tags to the site.\n\nEach tag must include a Photo, a Show, a Tag Name, a Link, and a Product Name. Add as much detail as you can but leave blank anything that doesn't apply."
            }
          </Text>
        </View>
        {/* ProductForm */}
        <View>
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', justifyContent: 'center', marginTop: 10 },
              dimensions.width
            )}
          >
            <View>
              {/* TagPhoto */}
              <Touchable
                onPress={() => {
                  const handler = async () => {
                    try {
                      const tagPhoto = await openImagePickerUtil({
                        mediaTypes: 'All',
                        allowsEditing: false,
                        quality: 0.2,
                        allowsMultipleSelection: false,
                        permissionErrorMessage:
                          'Sorry, we need media library permissions to make this work.',
                        showAlertOnPermissionError: true,
                        outputBase64: true,
                      });

                      setTagPhotoValue(tagPhoto);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  };
                  handler();
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: palettes.App.CardBorder,
                      borderRadius: 4,
                      borderWidth: 1,
                      justifyContent: 'center',
                      padding: [
                        { minWidth: Breakpoints.Mobile, value: 6 },
                        { minWidth: Breakpoints.Desktop, value: 12 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {tagPhotoValue ? null : (
                      <Icon
                        name={'FontAwesome/photo'}
                        color={palettes.App.CardBorder}
                        size={36}
                        style={StyleSheet.applyWidth(
                          { width: 40 },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {!tagPhotoValue ? null : (
                      <Image
                        {...GlobalStyles.ImageStyles(theme)['Image'].props}
                        resizeMode={'cover'}
                        source={{ uri: `${tagPhotoValue}` }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'].style,
                            { height: 175, width: 175 }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  <>
                    {tagPhotoValue ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App.CardBorder,
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        {'Upload Tag Photo*'}
                      </Text>
                    )}
                  </>
                  <>
                    {!tagPhotoValue ? null : (
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: palettes.App['Custom Color_2'],
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: [
                              { minWidth: Breakpoints.BigScreen, value: 18 },
                              { minWidth: Breakpoints.Desktop, value: 18 },
                            ],
                            textAlign: 'center',
                          },
                          dimensions.width
                        )}
                      >
                        {'Tag Photo Preview\nClick to Change Photo'}
                      </Text>
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* TagName */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newTagNameValue => {
                try {
                  const value69RKqLYm = newTagNameValue;
                  setTagNameValue(value69RKqLYm);
                  const tagNameValue = value69RKqLYm;
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={"Tag Name (e.g., John's Shirt) *"}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={tagNameValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* SeriesShow */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newSeriesShowValue => {
                try {
                  const valueVCEF7KaB = newSeriesShowValue;
                  setSeriesShowValue(valueVCEF7KaB);
                  const seriesShowValue = valueVCEF7KaB;
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Series / Show *'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={seriesShowValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Episode */}
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newEpisodeValue => {
                try {
                  setEpisodeValue(newEpisodeValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              autoCapitalize={'characters'}
              placeholder={'Episode (e.g., S1:E2)'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={episodeValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Network */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newNetworkValue => {
                try {
                  setNetworkValue(newNetworkValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Network'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={networkValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Character */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newCharacterValue => {
                try {
                  setCharacterValue(newCharacterValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Character'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={characterValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Actor */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newActorValue => {
                try {
                  setActorValue(newActorValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Actor'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={actorValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* ProductName */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newProductNameValue => {
                try {
                  setProductNameValue(newProductNameValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Product Name (as defined by seller) *'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={productNameValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* ProductLink */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newProductLinkValue => {
                try {
                  const valueNi84h96J = newProductLinkValue;
                  setProductLinkValue(valueNi84h96J);
                  const productLinkValue = valueNi84h96J;
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              keyboardType={'url'}
              placeholder={'Product Link/URL *'}
              placeholderTextColor={palettes.App.CardBorder}
              spellcheck={true}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={productLinkValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Brand */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newBrandValue => {
                try {
                  setBrandValue(newBrandValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Brand'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={brandValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Retailer */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newRetailerValue => {
                try {
                  setRetailerValue(newRetailerValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Retailer'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={retailerValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Genre */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newGenreValue => {
                try {
                  setGenreValue(newGenreValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Genre'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={genreValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* GenderPicker */}
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background.brand}
              dropDownBorderColor={theme.colors.border.brand}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.text.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newGenderPickerValue => {
                try {
                  setGenderValue(newGenderPickerValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.text.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              options={['Women', 'Men', 'Neutral']}
              placeholder={'Gender'}
              placeholderTextColor={palettes.App.CardBorder}
              style={[
                StyleSheet.applyWidth(
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderColor: palettes.App.CardBorder,
                    borderRadius: 4,
                    color: palettes.App['Custom #ffffff'],
                    fontFamily: 'SpaceGrotesk_400Regular',
                    fontSize: 13,
                    height: 35,
                    paddingBottom: 3,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 3,
                  },
                  dimensions.width
                ),
                { padding: 2 },
              ]}
              contentContainerStyle={{ padding: 2 }}
              value={genderValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* CategoryPicker */}
            <Picker
              autoDismissKeyboard={true}
              dropDownBackgroundColor={theme.colors.background.brand}
              dropDownBorderColor={theme.colors.border.brand}
              dropDownBorderRadius={8}
              dropDownBorderWidth={1}
              dropDownTextColor={theme.colors.text.strong}
              iconSize={24}
              leftIconMode={'inset'}
              mode={'native'}
              onValueChange={newCategoryPickerValue => {
                try {
                  setCategoryValue(newCategoryPickerValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              selectedIconColor={theme.colors.text.strong}
              selectedIconName={'Feather/check'}
              selectedIconSize={20}
              type={'solid'}
              options={[
                'Clothing',
                'Accessory',
                'Furniture',
                'Location',
                'Product',
              ]}
              placeholder={'Category'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderColor: palettes.App.CardBorder,
                  borderRadius: 4,
                  color: palettes.App['Custom #ffffff'],
                  fontFamily: 'SpaceGrotesk_400Regular',
                  fontSize: 13,
                  height: 35,
                  paddingBottom: 3,
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingTop: 3,
                },
                dimensions.width
              )}
              value={categoryValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Price */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newPriceValue => {
                try {
                  setPriceValue(newPriceValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              keyboardType={'decimal-pad'}
              placeholder={'Price'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={priceValue}
            />
          </View>

          <View
            style={StyleSheet.applyWidth(
              { borderRadius: 0, marginTop: 11 },
              dimensions.width
            )}
          >
            {/* Keywords */}
            <TextInput
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newKeywordsValue => {
                try {
                  setKeywordsValue(newKeywordsValue);
                } catch (err) {
                  Sentry.captureException(err);
                  console.error(err);
                }
              }}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['TagUploadInput'].props}
              placeholder={'Enter any other keywords here'}
              placeholderTextColor={palettes.App.CardBorder}
              style={StyleSheet.applyWidth(
                GlobalStyles.TextInputStyles(theme)['TagUploadInput'].style,
                dimensions.width
              )}
              value={keywordsValue}
            />
          </View>
        </View>
        {/* ErrorMessage */}
        <>
          {!errorMessage ? null : (
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors.background.danger,
                    fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                  }
                ),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
          )}
        </>
        {/* UploadTagButton */}
        <Button
          iconPosition={'left'}
          onPress={() => {
            const handler = async () => {
              try {
                const isFormValid = verifyForm();
                if (!isFormValid) {
                  return;
                }
                setIsLoading(true);
                const createTagResult = (
                  await tagsAPICreateTagPOST.mutateAsync({
                    actor: actorValue,
                    brand: brandValue,
                    category: categoryValue,
                    charcter: characterValue,
                    episode: episodeValue,
                    gender: genderValue,
                    genre: genreValue,
                    keywords: keywordsValue,
                    network: networkValue,
                    photo_base64: tagPhotoValue,
                    price: priceValue,
                    product_link: productLinkValue,
                    product_name: productNameValue,
                    retailer: retailerValue,
                    series_name: seriesShowValue,
                    tag_name: tagNameValue,
                  })
                )?.json;
                setIsLoading(false);
                clearForm();
                console.log('created tag', createTagResult);
                navigation.navigate('BottomTabNavigator', {
                  screen: 'HomeStackNavgiator',
                  params: {
                    screen: 'UnverifiedTagPreviewScreen',
                    params: { tagID: createTagResult?.id },
                  },
                });
              } catch (err) {
                Sentry.captureException(err);
                console.error(err);
              }
            };
            handler();
          }}
          {...GlobalStyles.ButtonStyles(theme)['PrimaryButton'].props}
          disabled={isLoading}
          loading={isLoading}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.ButtonStyles(theme)['PrimaryButton'].style,
              {
                backgroundColor: theme.colors.branding.primary,
                borderRadius: 11,
                fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                marginTop: 20,
              }
            ),
            dimensions.width
          )}
          title={'Upload Tag'}
        />
      </SimpleStyleKeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(TagUploadScreen);
