export default {
  LoginBGImg: require('../assets/images/LoginBGImg.png'),
  CategoryHand: require('../assets/images/CategoryHand.png'),
  User: require('../assets/images/User.jpeg'),
  Google: require('../assets/images/Google.png'),
  Apple: require('../assets/images/Apple.png'),
  FB: require('../assets/images/FB.png'),
  Sendmsg: require('../assets/images/Sendmsg.png'),
  Filter: require('../assets/images/Filter.png'),
  Group162742: require('../assets/images/Group162742.png'),
  PurpleBackgroundImage: require('../assets/images/PurpleBackgroundImage.jpg'),
  LogoDarkFull: require('../assets/images/LogoDarkFull.png'),
  LogoDarkSmall: require('../assets/images/LogoDarkSmall.png'),
  LogoWhiteFull: require('../assets/images/LogoWhiteFull.png'),
  LogoWhiteSmall: require('../assets/images/LogoWhiteSmall.png'),
  LogoPurpleFull: require('../assets/images/LogoPurpleFull.png'),
  LogoPurpleSmall: require('../assets/images/LogoPurpleSmall.png'),
  AppleAppStoreButton: require('../assets/images/AppleAppStoreButton.png'),
  PlayStoreButton: require('../assets/images/PlayStoreButton.png'),
};
