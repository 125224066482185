import React from 'react';
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleKeyboardAwareScrollView,
  SwitchRow,
  TabView,
  TabViewItem,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Image,
  Modal,
  Platform,
  Text,
  View,
} from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as NewXANOAPIApi from '../apis/NewXANOAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import MergeUserInfo from '../global-functions/MergeUserInfo';
import navigateToCompleteOnboarding from '../global-functions/navigateToCompleteOnboarding';
import pushToHistory from '../global-functions/pushToHistory';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import getPushTokenUtil from '../utils/getPushToken';
import openImagePickerUtil from '../utils/openImagePicker';
import parseBoolean from '../utils/parseBoolean';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';

const EditProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const [bioValue, setBioValue] = React.useState(
    Constants['USER']?._profile_of_user?.bio
  );
  const [confirmPasswordValue, setConfirmPasswordValue] = React.useState('');
  const [currentPasswordValue, setCurrentPasswordValue] = React.useState('');
  const [emailPermissionsValue, setEmailPermissionsValue] = React.useState(
    Constants['USER']?.allow_emails
  );
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isUpdatingBio, setIsUpdatingBio] = React.useState(false);
  const [isUpdatingName, setIsUpdatingName] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [name, setName] = React.useState(
    Constants['USER']?._profile_of_user?.name
  );
  const [nameErrorMessage, setNameErrorMessage] = React.useState('');
  const [newPasswordValue, setNewPasswordValue] = React.useState('');
  const [notificationPermissionsValue, setNotificationPermissionsValue] =
    React.useState(parseBoolean(Constants['USER']?.push_token));
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  // returns true if form is valid
  //
  const validateChangePasswordForm = () => {
    if (currentPasswordValue === '') {
      setErrorMessage('Please enter current password');
      return false;
    }
    if (newPasswordValue === '') {
      setErrorMessage('Please enter new password');
      return false;
    }
    if (confirmPasswordValue === '') {
      setErrorMessage('Please enter confirm password');
      return false;
    }
    if (newPasswordValue !== confirmPasswordValue) {
      setErrorMessage('New password and confirm password must be same');
      return false;
    }

    //check if new password is not same as current password
    if (newPasswordValue === currentPasswordValue) {
      setErrorMessage('New password and current password must be different');
      return false;
    }

    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
    if (!regex.test(newPasswordValue)) {
      setErrorMessage(
        'Password must be 8 to 20 characters long and must contain at least one numeric digit, one uppercase and one lowercase letter'
      );
      return false;
    }
    setErrorMessage('');
    return true;
  };

  const isSocialLogin = () => {
    return (
      Boolean(
        Constants['USER']?.google_oauth?.id ||
          Constants['USER']?.apple_oauth?.id
      ) && !Constants['USER'].password
    );
  };
  const newXANOAPIUpdateProfilePhotoPOST =
    NewXANOAPIApi.useUpdateProfilePhotoPOST();
  const newXANOAPIUpdateNamePOST = NewXANOAPIApi.useUpdateNamePOST();
  const newXANOAPIUpdateBioPOST = NewXANOAPIApi.useUpdateBioPOST();
  const newXANOAPITogglePushNotificationPOST =
    NewXANOAPIApi.useTogglePushNotificationPOST();
  const newXANOAPIDeleteAccountDELETE = NewXANOAPIApi.useDeleteAccountDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        pushToHistory(
          Variables,
          setGlobalVariableValue,
          segment,
          'EditProfileScreen',
          undefined
        );
        const meResult = (await NewXANOAPIApi.meGET(Constants))?.json;
        if (meResult?.code === 'ERROR_CODE_UNAUTHORIZED') {
          navigation.navigate('LoginScreen');
        }
        if (
          navigateToCompleteOnboarding(
            navigation,
            setGlobalVariableValue,
            Constants['USER']
          )
        ) {
          navigation.navigate('SignUpScreen', { completeOnBoarding: true });
        }
      } catch (err) {
        Sentry.captureException(err);
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      hasBottomSafeArea={false}
      hasTopSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        {
          alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
          backgroundColor: [
            {
              minWidth: Breakpoints.Desktop,
              value: palettes.App.BackgroundPurple,
            },
            {
              minWidth: Breakpoints.BigScreen,
              value: palettes.App.BackgroundPurple,
            },
            {
              minWidth: Breakpoints.Mobile,
              value: palettes.App.BackgroundPurple,
            },
          ],
          flex: 1,
          marginBottom: 0,
          marginTop: 0,
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: palettes.App.BackgroundPurple,
            flexDirection: 'row',
            height: 70,
            justifyContent: 'center',
            minHeight: 70,
          },
          dimensions.width
        )}
      >
        <IconButton
          onPress={() => {
            try {
              navigation.navigate('BottomTabNavigator', {
                screen: 'ProfileStackNavigator',
                params: { screen: 'ProfileScreen' },
              });
            } catch (err) {
              Sentry.captureException(err);
              console.error(err);
            }
          }}
          size={32}
          color={theme.colors.background.brand}
          icon={'Ionicons/chevron-back-outline'}
          style={StyleSheet.applyWidth(
            { left: 16, position: 'absolute' },
            dimensions.width
          )}
        />
        <Text
          accessible={true}
          {...GlobalStyles.TextStyles(theme)['Text'].props}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
              color: palettes.App['Custom #ffffff'],
              fontFamily: 'SpaceGrotesk_700Bold',
              fontSize: 20,
            }),
            dimensions.width
          )}
        >
          {'Profile Settings'}
        </Text>
      </View>

      <SimpleStyleKeyboardAwareScrollView
        enableAutomaticScroll={false}
        enableOnAndroid={false}
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
        viewIsInsideTabBar={false}
        {...GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
          'ResponsiveKeyboardAwareScrollView 7'
        ].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.SimpleStyleKeyboardAwareScrollViewStyles(theme)[
              'ResponsiveKeyboardAwareScrollView 7'
            ].style,
            {
              backgroundColor: [
                {
                  minWidth: Breakpoints.Mobile,
                  value: palettes.App.BackgroundPurple,
                },
                {
                  minWidth: Breakpoints.BigScreen,
                  value: palettes.App.BackgroundPurple,
                },
              ],
            }
          ),
          dimensions.width
        )}
      >
        <TabView
          activeColor={theme.colors.branding.primary}
          iconPosition={'top'}
          indicatorColor={theme.colors.branding.primary}
          keyboardDismissMode={'auto'}
          pressColor={theme.colors.branding.primary}
          scrollEnabled={false}
          swipeEnabled={true}
          tabsBackgroundColor={theme.colors.background.brand}
          initialTabIndex={0}
          style={StyleSheet.applyWidth(
            {
              fontFamily: 'SpaceGrotesk_400Regular',
              fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
              textTransform: 'capitalize',
            },
            dimensions.width
          )}
          tabBarPosition={'bottom'}
        >
          {/* ProfileTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                { backgroundColor: theme.colors.background.brand }
              ),
              dimensions.width
            )}
            title={'Profile '}
          >
            <SimpleStyleKeyboardAwareScrollView
              enableAutomaticScroll={false}
              enableOnAndroid={false}
              enableResetScrollToCoords={false}
              keyboardShouldPersistTaps={'never'}
              showsVerticalScrollIndicator={true}
              viewIsInsideTabBar={false}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  overflow: 'hidden',
                  padding: 20,
                },
                dimensions.width
              )}
            >
              {/* ProfileImage */}
              <View
                style={StyleSheet.applyWidth(
                  { alignContent: 'center', flexDirection: 'column' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Pressable
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const profileImg = await openImagePickerUtil({
                            mediaTypes: 'Images',
                            allowsEditing: true,
                            quality: 0.2,
                            allowsMultipleSelection: false,
                            permissionErrorMessage:
                              'Sorry, we need media library permissions to make this work.',
                            showAlertOnPermissionError: true,
                            outputBase64: true,
                          });

                          if (profileImg) {
                            setIsUploading(true);
                            const updateProfilePhotoResult = (
                              await newXANOAPIUpdateProfilePhotoPOST.mutateAsync(
                                { profile_photo: profileImg }
                              )
                            )?.json;
                            setIsUploading(false);
                            MergeUserInfo(
                              Variables,
                              setGlobalVariableValue,
                              updateProfilePhotoResult,
                              undefined
                            );
                          } else {
                          }
                        } catch (err) {
                          Sentry.captureException(err);
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      {
                        borderColor: palettes.App['Custom Color_10'],
                        borderRadius: [
                          { minWidth: Breakpoints.Mobile, value: 100 },
                          { minWidth: Breakpoints.Desktop, value: 150 },
                        ],
                        borderWidth: 5,
                        height: [
                          { minWidth: Breakpoints.Desktop, value: 150 },
                          { minWidth: Breakpoints.Mobile, value: 100 },
                        ],
                        width: [
                          { minWidth: Breakpoints.Desktop, value: 150 },
                          { minWidth: Breakpoints.Mobile, value: 100 },
                        ],
                      },
                      dimensions.width
                    )}
                  >
                    <>
                      {!isUploading ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: 'center',
                              backgroundColor: palettes.App['BG Gray'],
                              borderColor: palettes.App['Border Color'],
                              borderRadius: 9,
                              justifyContent: 'center',
                              minHeight: 48,
                              position: 'absolute',
                              top: 26,
                              width: '100%',
                            },
                            dimensions.width
                          )}
                        >
                          <ActivityIndicator
                            animating={true}
                            hidesWhenStopped={true}
                            {...GlobalStyles.ActivityIndicatorStyles(theme)[
                              'Activity Indicator'
                            ].props}
                            size={'large'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.ActivityIndicatorStyles(theme)[
                                'Activity Indicator'
                              ].style,
                              dimensions.width
                            )}
                          />
                        </View>
                      )}
                    </>
                    {/* ImageView */}
                    <>
                      {isUploading ? null : (
                        <View>
                          <>
                            {!Constants['USER']?._profile_of_user?.profile_photo
                              ?.url ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={{
                                  uri: `${Constants['USER']?._profile_of_user?.profile_photo?.url}`,
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                      ],
                                      height: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          {/* EmptyImage */}
                          <>
                            {Constants['USER']?._profile_of_user?.profile_photo
                              ?.url ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={{
                                  uri: 'https://storage.googleapis.com/x8x4-ck6z-yoac.n7.xano.io/vault/iOa7Bcte/4f-INq0TzY8W0sD1yXktaxNLmqY/0EI1bg../image%20%286%29.png',
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      borderRadius: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                      ],
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                      ],
                                      width: [
                                        {
                                          minWidth: Breakpoints.Desktop,
                                          value: 140,
                                        },
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 90,
                                        },
                                      ],
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                        </View>
                      )}
                    </>
                  </Pressable>
                </View>
              </View>
              {/* Name */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['label'].props}
                  numberOfLines={1}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['label'].style,
                      { fontSize: { minWidth: Breakpoints.Desktop, value: 14 } }
                    ),
                    dimensions.width
                  )}
                >
                  {'Name'}
                </Text>
                <>
                  {!isUpdatingName ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['BG Gray'],
                          borderColor: palettes.App['Border Color'],
                          borderRadius: 9,
                          justifyContent: 'center',
                          minHeight: 48,
                          position: 'absolute',
                          top: 26,
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                {/* Name */}
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  onBlur={() => {
                    const handler = async () => {
                      try {
                        if (!name?.length) {
                          setNameErrorMessage('Name field can not be empty');
                        }
                        if (name?.length === 0) {
                          return;
                        }
                        setNameErrorMessage('');
                        setIsUpdatingName(true);
                        const updateNameResult = (
                          await newXANOAPIUpdateNamePOST.mutateAsync({
                            name: name,
                          })
                        )?.json;
                        setIsUpdatingName(false);
                        if (!updateNameResult?.message) {
                          MergeUserInfo(
                            Variables,
                            setGlobalVariableValue,
                            updateNameResult,
                            undefined
                          );
                        }
                        if (updateNameResult?.message) {
                          setNameErrorMessage(updateNameResult?.message);
                        }
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onChangeText={newNameValue => {
                    try {
                      setName(newNameValue);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter a value...'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['FormInput'].props}
                  autoCapitalize={'words'}
                  disabled={isUpdatingName}
                  editable={!isUpdatingName ?? true}
                  keyboardType={'default'}
                  placeholderTextColor={palettes.App['Border Color']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['FormInput'].style,
                      {
                        fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                        height: 48,
                      }
                    ),
                    dimensions.width
                  )}
                  textContentType={'name'}
                  value={name}
                />
                {/* nameErrorMessage */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { color: theme.colors.background.danger }
                    ),
                    dimensions.width
                  )}
                >
                  {nameErrorMessage}
                </Text>
              </View>
              {/* Bio */}
              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* Label */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['label'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['label'].style,
                      { fontSize: { minWidth: Breakpoints.Desktop, value: 14 } }
                    ),
                    dimensions.width
                  )}
                >
                  {'Bio'}
                </Text>
                <>
                  {!isUpdatingBio ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          backgroundColor: palettes.App['BG Gray'],
                          borderColor: palettes.App['Border Color'],
                          borderRadius: 9,
                          justifyContent: 'center',
                          marginTop: 26,
                          minHeight: 100,
                          position: 'absolute',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      <ActivityIndicator
                        animating={true}
                        hidesWhenStopped={true}
                        size={'small'}
                        {...GlobalStyles.ActivityIndicatorStyles(theme)[
                          'Activity Indicator'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.ActivityIndicatorStyles(theme)[
                            'Activity Indicator'
                          ].style,
                          dimensions.width
                        )}
                      />
                    </View>
                  )}
                </>
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={500}
                  multiline={true}
                  numberOfLines={4}
                  onBlur={() => {
                    const handler = async () => {
                      try {
                        setIsUpdatingBio(true);
                        (
                          await newXANOAPIUpdateBioPOST.mutateAsync({
                            bio: bioValue,
                          })
                        )?.json;
                        setIsUpdatingBio(false);
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  onChangeText={newTextAreaValue => {
                    const handler = async () => {
                      try {
                        setBioValue(newTextAreaValue);
                        (
                          await newXANOAPIUpdateBioPOST.mutateAsync({
                            bio: 'some fashion designer',
                          })
                        )?.json;
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  textAlignVertical={'top'}
                  webShowOutline={true}
                  {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
                  disabled={isUpdatingBio}
                  editable={!isUpdatingBio}
                  placeholder={'Enter your bio'}
                  placeholderTextColor={palettes.App['Border Color']}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                      { marginTop: 10 }
                    ),
                    dimensions.width
                  )}
                  value={bioValue}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                {/* LogoutButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'AUTH_TOKEN',
                        value: '',
                      });
                      setGlobalVariableValue({
                        key: 'USER',
                        value: '',
                      });
                      setGlobalVariableValue({
                        key: 'likesCache',
                        value: {},
                      });
                      setGlobalVariableValue({
                        key: 'sharesCache',
                        value: {},
                      });
                      setGlobalVariableValue({
                        key: 'isLikedByUserCache',
                        value: {},
                      });
                      navigation.navigate('WelcomeScreen');
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Outlined Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Outlined Button'].style,
                      {
                        borderColor: 'theme.colors["Custom #d3536d"]',
                        color: 'theme.colors["Custom #d3536d"]',
                        fontFamily: 'SpaceGrotesk_700Bold',
                        fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Logout'}
                />
              </View>
            </SimpleStyleKeyboardAwareScrollView>
          </TabViewItem>
          {/* PermissionsTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                { backgroundColor: theme.colors.background.brand }
              ),
              dimensions.width
            )}
            title={'Permissions'}
          >
            {/* Form */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  overflow: 'hidden',
                  paddingLeft: 20,
                  paddingRight: 20,
                },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['label'].style,
                    {
                      marginBottom: 20,
                      marginTop: 20,
                      textAlign: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Modify your communication settings here'}
              </Text>
              {/* NotificiationPermissions */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignSelf: {
                      minWidth: Breakpoints.Desktop,
                      value: 'center',
                    },
                    width: { minWidth: Breakpoints.Desktop, value: 600 },
                  },
                  dimensions.width
                )}
              >
                {/* NotificationPermissions */}
                <>
                  {Platform.OS === 'web' ? null : (
                    <SwitchRow
                      onValueChange={newNotificationPermissionsValue => {
                        const handler = async () => {
                          try {
                            setNotificationPermissionsValue(
                              newNotificationPermissionsValue
                            );
                            if (newNotificationPermissionsValue) {
                              console.log('getting push token');
                              const pushToken = await getPushTokenUtil({
                                permissionErrorMessage:
                                  'Sorry, we need notifications permissions to make this work.',
                                deviceErrorMessage:
                                  'Must use physical device for Push Notifications.',
                                showAlertOnPermissionError: true,
                                showAlertOnDeviceError: true,
                              });

                              console.log(pushToken);
                              const withTokenResult = (
                                await newXANOAPITogglePushNotificationPOST.mutateAsync(
                                  { push_token: pushToken }
                                )
                              )?.json;
                              MergeUserInfo(
                                Variables,
                                setGlobalVariableValue,
                                undefined,
                                withTokenResult
                              );
                            } else {
                              console.log('clearing push token');
                              (
                                await newXANOAPITogglePushNotificationPOST.mutateAsync(
                                  { push_token: '' }
                                )
                              )?.json;
                              MergeUserInfo(
                                Variables,
                                setGlobalVariableValue,
                                undefined,
                                { push_token: '' }
                              );
                            }
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.SwitchRowStyles(theme)['Switch Row']
                        .props}
                      label={'Allow Push Notifications'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.SwitchRowStyles(theme)['Switch Row']
                            .style,
                          {
                            color: palettes.App['Custom Color'],
                            fontFamily: 'SpaceGrotesk_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                            marginBottom: 20,
                            textTransform: 'capitalize',
                          }
                        ),
                        dimensions.width
                      )}
                      value={notificationPermissionsValue}
                    />
                  )}
                </>
                {/* EmailPermissions */}
                <SwitchRow
                  onValueChange={newEmailPermissionsValue => {
                    const handler = async () => {
                      try {
                        setEmailPermissionsValue(newEmailPermissionsValue);
                        const toggleEmailPermissonResult = (
                          await NewXANOAPIApi.toggleEmailPermissionGET(
                            Constants
                          )
                        )?.json;
                        console.log(toggleEmailPermissonResult);
                        MergeUserInfo(
                          Variables,
                          setGlobalVariableValue,
                          undefined,
                          newEmailPermissionsValue
                        );
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.SwitchRowStyles(theme)['Switch Row'].props}
                  label={'Allow Emails from Montage'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.SwitchRowStyles(theme)['Switch Row'].style,
                      {
                        color: palettes.App['Custom Color'],
                        fontFamily: 'SpaceGrotesk_400Regular',
                        fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                      }
                    ),
                    dimensions.width
                  )}
                  value={emailPermissionsValue}
                />
              </View>
            </View>
            <Divider
              color={theme.colors.border.brand}
              {...GlobalStyles.DividerStyles(theme)['Divider'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.DividerStyles(theme)['Divider'].style,
                  { marginBottom: 12, marginTop: 12 }
                ),
                dimensions.width
              )}
            />
            {/* BlockedUsers */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors.background.brand,
                  overflow: 'hidden',
                  paddingLeft: 20,
                  paddingRight: 20,
                },
                dimensions.width
              )}
            >
              {/* Label */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['label'].props}
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['label'].style,
                    {
                      marginBottom: 20,
                      marginTop: 20,
                      textAlign: {
                        minWidth: Breakpoints.Desktop,
                        value: 'center',
                      },
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Here are your blocked users'}
              </Text>
              <SimpleStyleFlatList
                data={Constants['USER']?._hidden_user_ids_of_user}
                horizontal={false}
                inverted={false}
                keyExtractor={(listData, index) =>
                  listData?.id ??
                  listData?.uuid ??
                  index?.toString() ??
                  JSON.stringify(listData)
                }
                keyboardShouldPersistTaps={'never'}
                listKey={'S9kZ9kKt'}
                nestedScrollEnabled={false}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <View
                      style={StyleSheet.applyWidth(
                        { flexDirection: 'row', marginBottom: 8 },
                        dimensions.width
                      )}
                    >
                      <>
                        {!listData?._profile_of_user?.profile_photo
                          ?.url ? null : (
                          <Image
                            resizeMode={'cover'}
                            {...GlobalStyles.ImageStyles(theme)['Image'].props}
                            source={{
                              uri: `${listData?._profile_of_user?.profile_photo?.url}`,
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ImageStyles(theme)['Image'].style,
                                { borderRadius: 6, height: 40, width: 40 }
                              ),
                              dimensions.width
                            )}
                          />
                        )}
                      </>
                      <>
                        {listData?._profile_of_user?.profile_photo
                          ?.url ? null : (
                          <Icon
                            color={palettes.App['Custom #5f5a53']}
                            name={'FontAwesome/user-circle'}
                            size={36}
                          />
                        )}
                      </>
                      {/* Texts */}
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1, paddingLeft: 8, paddingRight: 8 },
                          dimensions.width
                        )}
                      >
                        {/* Name */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {listData?._profile_of_user?.name}
                        </Text>
                        {/* Handle */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: palettes.App['Custom #5f5a53'] }
                            ),
                            dimensions.width
                          )}
                        >
                          {listData?._profile_of_user?.handle}
                        </Text>
                      </View>
                      {/* Action */}
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingLeft: 8, paddingRight: 8 },
                          dimensions.width
                        )}
                      >
                        <IconButton
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const toggleBlockResult = (
                                  await NewXANOAPIApi.toggleBlockGET(
                                    Constants,
                                    { user_id: listData?._user?.id }
                                  )
                                )?.json;
                                setGlobalVariableValue({
                                  key: 'USER',
                                  value: toggleBlockResult,
                                });
                              } catch (err) {
                                Sentry.captureException(err);
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          size={32}
                          icon={'AntDesign/unlock'}
                        />
                      </View>
                    </View>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            </View>
          </TabViewItem>
          {/* SecurityTab */}
          <TabViewItem
            {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.TabViewItemStyles(theme)['Tab View Item'].style,
                {
                  alignItems: {
                    minWidth: Breakpoints.Desktop,
                    value: 'center',
                  },
                  backgroundColor: theme.colors.background.brand,
                }
              ),
              dimensions.width
            )}
            title={'Security'}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flex: 1,
                  justifyContent: 'flex-end',
                  maxWidth: { minWidth: Breakpoints.Desktop, value: 600 },
                  padding: 12,
                  width: { minWidth: Breakpoints.Desktop, value: '100%' },
                },
                dimensions.width
              )}
            >
              {/* Change Password */}
              <>
                {isSocialLogin() ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: theme.colors.background.brand,
                        flex: 0.8,
                        overflow: 'hidden',
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* CurrentPassword */}
                    <View>
                      {/* Label */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        numberOfLines={1}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Current Password'}
                      </Text>
                      {/* CurrentPassword */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newCurrentPasswordValue => {
                          try {
                            setCurrentPasswordValue(newCurrentPasswordValue);
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['FormInput']
                          .props}
                        editable={true}
                        placeholder={'Enter Current Password'}
                        placeholderTextColor={palettes.App['Border Color']}
                        secureTextEntry={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['FormInput']
                              .style,
                            { height: 48 }
                          ),
                          dimensions.width
                        )}
                        value={currentPasswordValue}
                      />
                    </View>
                    {/* NewPassword */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 12 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        numberOfLines={1}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'New Password'}
                      </Text>
                      {/* NewPassword */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newNewPasswordValue => {
                          try {
                            setNewPasswordValue(newNewPasswordValue);
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['FormInput']
                          .props}
                        editable={true}
                        placeholder={'Enter New Password'}
                        placeholderTextColor={palettes.App['Border Color']}
                        secureTextEntry={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['FormInput']
                              .style,
                            { height: 48 }
                          ),
                          dimensions.width
                        )}
                        value={newPasswordValue}
                      />
                    </View>
                    {/* NewPasswordRepeat */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 12 },
                        dimensions.width
                      )}
                    >
                      {/* Label */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['label'].props}
                        numberOfLines={1}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['label'].style,
                          dimensions.width
                        )}
                      >
                        {'Confirm New Password'}
                      </Text>
                      {/* ConfirmPassword */}
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newConfirmPasswordValue => {
                          try {
                            setConfirmPasswordValue(newConfirmPasswordValue);
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        }}
                        webShowOutline={true}
                        {...GlobalStyles.TextInputStyles(theme)['FormInput']
                          .props}
                        editable={true}
                        placeholder={'Confirm Your Password'}
                        placeholderTextColor={palettes.App['Border Color']}
                        secureTextEntry={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextInputStyles(theme)['FormInput']
                              .style,
                            { height: 48, width: '100%' }
                          ),
                          dimensions.width
                        )}
                        value={confirmPasswordValue}
                      />
                    </View>
                    {/* ErrorMessage */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.background.danger,
                          fontFamily: 'SpaceGrotesk_400Regular',
                        },
                        dimensions.width
                      )}
                    >
                      {errorMessage}
                    </Text>
                    {/* UpdatePasswordButton */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            if (!validateChangePasswordForm()) {
                              return;
                            }
                            const changePasswordResult = (
                              await NewXANOAPIApi.changePasswordGET(Constants, {
                                current_password: currentPasswordValue,
                                new_password: newPasswordValue,
                              })
                            )?.json;
                            setErrorMessage(changePasswordResult?.message);
                            if (
                              changePasswordResult?.message === 'Token Expired'
                            ) {
                              navigation.navigate('LoginScreen');
                            }
                            if (changePasswordResult?.message) {
                              return;
                            }
                            setCurrentPasswordValue('');
                            setConfirmPasswordValue('');
                            setNewPasswordValue('');

                            showAlertUtil({
                              title: undefined,
                              message: changePasswordResult?.status,
                              buttonText: undefined,
                            });
                          } catch (err) {
                            Sentry.captureException(err);
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['PrimaryButton']
                        .props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['PrimaryButton'].style,
                        dimensions.width
                      )}
                      title={'Update Password'}
                    />
                  </View>
                )}
              </>
              {/* TermsLinks */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: 0.1,
                    justifyContent: 'flex-end',
                    marginLeft: 20,
                    marginRight: 20,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    dimensions.width
                  )}
                >
                  {'View our '}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('TermsAndConditionsScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.LinkStyles(theme)['Link'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.LinkStyles(theme)['Link'].style,
                      dimensions.width
                    )}
                    title={'Terms & Conditions'}
                  />
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      dimensions.width
                    )}
                  >
                    {' and '}
                  </Text>
                  {/* Link 2 */}
                  <Link
                    accessible={true}
                    onPress={() => {
                      try {
                        navigation.navigate('PrivacyPolicyScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    }}
                    {...GlobalStyles.LinkStyles(theme)['Link'].props}
                    style={StyleSheet.applyWidth(
                      GlobalStyles.LinkStyles(theme)['Link'].style,
                      dimensions.width
                    )}
                    title={'Privacy Policy'}
                  />
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flex: 0.1,
                    justifyContent: 'flex-end',
                    marginLeft: 20,
                    marginRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* DeleteAccountButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowConfirmModal(true);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['PrimaryButton'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['PrimaryButton'].style,
                      {
                        backgroundColor: theme.colors.background.danger,
                        fontFamily: 'SpaceGrotesk_700Bold',
                        fontSize: 16,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Delete Account'}
                />
              </View>
            </View>
          </TabViewItem>
        </TabView>
      </SimpleStyleKeyboardAwareScrollView>
      {/* ConfirmDeleteModal */}
      <Modal
        supportedOrientations={['portrait', 'landscape']}
        animationType={'fade'}
        presentationStyle={'formSheet'}
        transparent={true}
        visible={showConfirmModal}
      >
        <BlurView
          intensity={50}
          tint={'default'}
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          style={{
            height: dimensions.height,
            width: dimensions.width,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* Container */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: '"rgba(252, 252, 252, 0.8)"',
                borderColor: palettes.App['Border Color'],
                borderRadius: 16,
                borderWidth: 1,
                maxWidth: 400,
                width: '90%',
              },
              dimensions.width
            )}
          >
            {/* ModalHeader */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: [
                    { minWidth: Breakpoints.Desktop, value: 'center' },
                    { minWidth: Breakpoints.Mobile, value: 'center' },
                  ],
                  borderBottomWidth: 1,
                  borderColor: palettes.App['Border Color'],
                  flexDirection: 'row',
                  padding: 16,
                  paddingBottom: 8,
                },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      alignSelf: 'auto',
                      flex: 1,
                      fontFamily: 'SpaceGrotesk_700Bold',
                      fontSize: 16,
                      textAlign: 'left',
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Delete Account? '}
              </Text>
              {/* CloseModalButton */}
              <IconButton
                onPress={() => {
                  try {
                    setShowConfirmModal(false);
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
                size={32}
                color={theme.colors.text.strong}
                icon={'AntDesign/closecircleo'}
              />
            </View>
            {/* ModalBody */}
            <View
              style={StyleSheet.applyWidth(
                { flexGrow: 1, flexShrink: 0, padding: 16 },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  dimensions.width
                )}
              >
                {
                  'Your account, including all comments, will be deleted permanently.'
                }
              </Text>
            </View>
            {/* ModalFooter */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 9,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  padding: 16,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginRight: 4 },
                  dimensions.width
                )}
              >
                {/* CancelButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    try {
                      setShowConfirmModal(false);
                    } catch (err) {
                      Sentry.captureException(err);
                      console.error(err);
                    }
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: '"rgba(0, 0, 0, 0)"',
                        borderColor: theme.colors.branding.primary,
                        borderWidth: 1,
                        color: theme.colors.branding.primary,
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Cancel'}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, marginLeft: 4 },
                  dimensions.width
                )}
              >
                {/* ConfirmButton */}
                <Button
                  iconPosition={'left'}
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setIsDeleting(true);
                        const result = (
                          await newXANOAPIDeleteAccountDELETE.mutateAsync({
                            user_id: Constants['USER']?.id,
                          })
                        )?.json;
                        console.log(result);
                        setIsDeleting(false);
                        setShowConfirmModal(false);
                        setGlobalVariableValue({
                          key: 'USER',
                          value: '',
                        });
                        setGlobalVariableValue({
                          key: 'AUTH_TOKEN',
                          value: '',
                        });
                        navigation.navigate('WelcomeScreen');
                      } catch (err) {
                        Sentry.captureException(err);
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  disabled={isDeleting}
                  loading={isDeleting}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      {
                        backgroundColor: palettes.App['Custom Color_11'],
                        lineHeight: 22,
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Yes, Delete!'}
                />
              </View>
            </View>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(EditProfileScreen);
