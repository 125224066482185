import React, { Fragment } from 'react';
import { StyleSheet, Text } from 'react-native';

export var TAG_REPLACEMENT = {
  highlightPreTag: '<mark>',
  highlightPostTag: '</mark>',
};

export function getHighlightedParts(highlightedValue) {
  // @MAJOR: this should use TAG_PLACEHOLDER
  var highlightPostTag = TAG_REPLACEMENT.highlightPostTag,
    highlightPreTag = TAG_REPLACEMENT.highlightPreTag;
  var splitByPreTag = highlightedValue.split(highlightPreTag);
  var firstValue = splitByPreTag.shift();
  var elements = !firstValue
    ? []
    : [
        {
          value: firstValue,
          isHighlighted: false,
        },
      ];
  splitByPreTag.forEach(function (split) {
    var splitByPostTag = split.split(highlightPostTag);
    elements.push({
      value: splitByPostTag[0],
      isHighlighted: true,
    });
    if (splitByPostTag[1] !== '') {
      elements.push({
        value: splitByPostTag[1],
        isHighlighted: false,
      });
    }
  });
  return elements;
}

export function getPropertyByPath(object, path) {
  var parts = Array.isArray(path) ? path : path.split('.');
  return parts.reduce(function (current, key) {
    return current && current[key];
  }, object);
}

function HighlightPart({ children, style }) {
  return <Text style={style}>{children}</Text>;
}

export function Highlight({
  hit,
  attribute,
  separator = ', ',
  highlightedStyle = styles.highlighted,
  nonHighlightedStyle = styles.nonHighlighted,
}) {
  const { value: attributeValue = '' } =
    getPropertyByPath(hit._highlightResult, attribute) || {};
  const parts = getHighlightedParts(attributeValue);

  return (
    <>
      {parts.map((part, partIndex) => {
        if (Array.isArray(part)) {
          const isLastPart = partIndex === parts.length - 1;
          return (
            <Fragment key={partIndex}>
              {part.map((subPart, subPartIndex) => {
                return (
                  <HighlightPart
                    key={subPartIndex}
                    style={
                      subPart.isHighlighted
                        ? highlightedStyle
                        : nonHighlightedStyle
                    }
                  >
                    {subPart}
                  </HighlightPart>
                );
              })}

              {!isLastPart && separator}
            </Fragment>
          );
        }

        return (
          <HighlightPart
            key={partIndex}
            style={part.isHighlighted ? highlightedStyle : nonHighlightedStyle}
          >
            {part.value}
          </HighlightPart>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  highlighted: {
    fontWeight: 'bold',
    backgroundColor: '#f5df4d',
    color: '#6f6106',
  },
  nonHighlighted: {
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    color: 'black',
  },
});
