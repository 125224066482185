export default {
  Inter_500Medium: require('../assets/fonts/Inter_500Medium.ttf'),
  Nunito_400Regular: require('../assets/fonts/Nunito_400Regular.ttf'),
  Poppins_700Bold: require('../assets/fonts/Poppins_700Bold.ttf'),
  Poppins_400Regular: require('../assets/fonts/Poppins_400Regular.ttf'),
  SpaceGrotesk_600SemiBold: require('../assets/fonts/SpaceGrotesk_600SemiBold.ttf'),
  SpaceGrotesk_700Bold: require('../assets/fonts/SpaceGrotesk_700Bold.ttf'),
  SpaceGrotesk_400Regular: require('../assets/fonts/SpaceGrotesk_400Regular.ttf'),
  SpaceGrotesk_500Medium: require('../assets/fonts/SpaceGrotesk_500Medium.ttf'),
  SpaceGrotesk_300Light: require('../assets/fonts/SpaceGrotesk_300Light.ttf'),
};
