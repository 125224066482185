const navigateToCompleteOnboarding = (
  navigation,
  setGlobalVariableValue,
  user
) => {
  if (!user) return false;

  if (user && !user?.id) {
    setGlobalVariableValue({
      key: 'USER',
      value: '',
    });
    setGlobalVariableValue({
      key: 'AUTH_TOKEN',
      value: '',
    });

    navigation.navigate('WelcomeScreen');
    return false;
  }

  const profile = user?._profile || user?._profile_of_user;

  return user?.id && !profile.handle;
};

export default navigateToCompleteOnboarding;
