import * as StyleSheet from './utils/StyleSheet';

import Breakpoints from './utils/Breakpoints';

import palettes from './themes/palettes';

export const FetchStyles = theme =>
  StyleSheet.create({ Fetch: { style: { minHeight: 40 }, props: {} } });

export const ActivityIndicatorStyles = theme =>
  StyleSheet.create({
    'Activity Indicator': { style: { height: 36, width: 36 }, props: {} },
  });

export const ViewStyles = theme =>
  StyleSheet.create({
    AHA: {
      style: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 30,
      },
      props: {},
    },
    'Commets Container': {
      style: {
        backgroundColor: palettes.App['BG Gray'],
        borderRadius: 9,
        borderWidth: 1,
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        maxWidth: [
          { minWidth: Breakpoints.Tablet, value: 500 },
          { minWidth: Breakpoints.BigScreen, value: 700 },
        ],
        width: { minWidth: Breakpoints.Tablet, value: '100%' },
      },
      props: {},
    },
    'Discover Buttons': {
      style: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 5,
        marginRight: 5,
      },
      props: {},
    },
    'Floating Back Button 14': {
      style: {
        bottom: 100,
        justifyContent: 'center',
        position: 'absolute',
        right: 28,
      },
      props: {},
    },
    'FloatingBackButtonHeader 2': {
      style: {
        backgroundColor: palettes.App.BackgroundPurple,
        left: 0,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingTop: 10,
        position: 'absolute',
        right: [
          { minWidth: Breakpoints.Tablet, value: '13%' },
          { minWidth: Breakpoints.Laptop, value: '14%' },
        ],
        top: 0,
        zIndex: 9999,
      },
      props: {},
    },
    HorizontalActions: {
      style: {
        alignContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 8,
      },
      props: {},
    },
    LoginModalView: {
      style: {
        alignItems: 'center',
        alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
        bottom: 0,
        flex: 1,
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: { minWidth: Breakpoints.Laptop, value: '100%' },
        zIndex: 101,
      },
      props: {},
    },
    ResponsiveAvatarView: {
      style: {
        alignItems: 'flex-end',
        borderColor: palettes.App['BG Gray'],
        borderRadius: [
          { minWidth: Breakpoints.Desktop, value: 150 },
          { minWidth: Breakpoints.Mobile, value: 100 },
          { minWidth: Breakpoints.BigScreen, value: 200 },
        ],
        borderWidth: 2,
        height: [
          { minWidth: Breakpoints.Mobile, value: 100 },
          { minWidth: Breakpoints.BigScreen, value: 200 },
          { minWidth: Breakpoints.Desktop, value: 150 },
        ],
        width: [
          { minWidth: Breakpoints.Mobile, value: 100 },
          { minWidth: Breakpoints.BigScreen, value: 200 },
          { minWidth: Breakpoints.Desktop, value: 150 },
        ],
      },
      props: {},
    },
    ResponsiveSearchBoxView: {
      style: {
        alignItems: 'center',
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Mobile, value: 'stretch' },
        ],
        backgroundColor: palettes.App['BG Gray'],
        borderBottomWidth: 1,
        borderColor: 'theme.colors["Custom Color_4"]',
        borderLeftWidth: 1,
        borderRadius: 24,
        borderRightWidth: 1,
        borderTopWidth: 1,
        flexDirection: 'row',
        height: 40,
        justifyContent: 'space-between',
        marginBottom: 9,
        marginTop: 9,
        paddingLeft: 20,
        paddingRight: 20,
        width: { minWidth: Breakpoints.Laptop, value: 600 },
      },
      props: {},
    },
    ResponsiveWrapperView: {
      style: {
        alignSelf: [
          { minWidth: Breakpoints.Desktop, value: 'center' },
          { minWidth: Breakpoints.Laptop, value: 'center' },
        ],
        backgroundColor: palettes.App.BackgroundPurple,
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: [
          { minWidth: Breakpoints.Mobile, value: '100%' },
          { minWidth: Breakpoints.Desktop, value: '100%' },
        ],
      },
      props: {},
    },
    'SoicalLogins 2': { style: { flexDirection: 'row' }, props: {} },
    StepBodyView: {
      style: {
        flex: 1,
        justifyContent: { minWidth: Breakpoints.Tablet, value: 'center' },
      },
      props: {},
    },
    StepView: { style: { margin: 40 }, props: {} },
    Terms: {
      style: { alignItems: 'center', flexDirection: 'row', marginTop: 20 },
      props: {},
    },
    Wrapper: {
      style: {
        alignItems: { minWidth: Breakpoints.Tablet, value: 'center' },
        backgroundColor: palettes.App.BackgroundPurple,
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: { minWidth: Breakpoints.Desktop, value: 1024 },
        width: { minWidth: Breakpoints.Laptop, value: '100%' },
      },
      props: {},
    },
    header: {
      style: {
        alignItems: 'center',
        backgroundColor: palettes.App.BackgroundPurple,
        height: 70,
        justifyContent: 'center',
      },
      props: {},
    },
    'login modal': {
      style: { left: 0, position: 'absolute', top: 0 },
      props: {},
    },
  });

export const ImageStyles = theme =>
  StyleSheet.create({
    Image: { style: { height: 100, width: 100 }, props: {} },
    ResonsiveAvatarImage: {
      style: {
        borderColor: palettes.App['Border Color'],
        borderRadius: [
          { minWidth: Breakpoints.Desktop, value: 145 },
          { minWidth: Breakpoints.BigScreen, value: 195 },
          { minWidth: Breakpoints.Mobile, value: 95 },
        ],
        height: [
          { minWidth: Breakpoints.BigScreen, value: 195 },
          { minWidth: Breakpoints.Mobile, value: 95 },
          { minWidth: Breakpoints.Desktop, value: 145 },
        ],
        overflow: 'hidden',
        width: [
          { minWidth: Breakpoints.Mobile, value: 95 },
          { minWidth: Breakpoints.Desktop, value: 145 },
          { minWidth: Breakpoints.BigScreen, value: 195 },
        ],
      },
      props: {},
    },
    ResponsiveProductImage: { style: {}, props: {} },
  });

export const TabViewItemStyles = theme =>
  StyleSheet.create({ 'Tab View Item': { style: { flex: 1 }, props: {} } });

export const SwitchRowStyles = theme =>
  StyleSheet.create({
    'Switch Row': { style: { marginLeft: 0, marginRight: 0 }, props: {} },
  });

export const ButtonStyles = theme =>
  StyleSheet.create({
    Button: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'SpaceGrotesk_700Bold',
        textAlign: 'center',
      },
      props: {},
    },
    'Outlined Button': {
      style: {
        backgroundColor: '"rgba(0, 0, 0, 0)"',
        borderColor: theme.colors.branding.primary,
        borderRadius: 8,
        borderWidth: 1,
        color: theme.colors.branding.primary,
        fontFamily: 'System',
        fontWeight: '700',
        textAlign: 'center',
      },
      props: {},
    },
    PrimaryButton: {
      style: {
        backgroundColor: theme.colors.branding.primary,
        borderRadius: 8,
        fontFamily: 'SpaceGrotesk_600SemiBold',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 24 },
        ],
        height: [
          { minWidth: Breakpoints.Mobile, value: 50 },
          { minWidth: Breakpoints.Desktop, value: 60 },
        ],
        marginTop: 12,
        textAlign: 'center',
      },
      props: {},
    },
  });

export const LinkStyles = theme =>
  StyleSheet.create({
    Link: { style: { color: theme.colors.branding.primary }, props: {} },
    ResponsiveEditLink: {
      style: {
        color: theme.colors.branding.primary,
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
        marginTop: 12,
      },
      props: {},
    },
  });

export const FlatListStyles = theme =>
  StyleSheet.create({
    ResponsiveCommentsList: {
      style: {
        alignItems: { minWidth: Breakpoints.Tablet, value: 'stretch' },
        alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
        flexDirection: 'column',
      },
      props: {},
    },
  });

export const DividerStyles = theme =>
  StyleSheet.create({ Divider: { style: { height: 1 }, props: {} } });

export const TextStyles = theme =>
  StyleSheet.create({
    ResponsiveBioText: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
      },
      props: {},
    },
    ResponsiveCommentBody: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 18 },
          { minWidth: Breakpoints.Desktop, value: 20 },
        ],
      },
      props: {},
    },
    ResponsiveCommentName: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: { minWidth: Breakpoints.Desktop, value: 20 },
        marginTop: 5,
      },
      props: {},
    },
    ResponsiveCommentTimeAgo: {
      style: {
        color: palettes.App.TextPlaceholder,
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 11 },
          { minWidth: Breakpoints.BigScreen, value: 14 },
          { minWidth: Breakpoints.Desktop, value: 18 },
        ],
      },
      props: {},
    },
    ResponsiveHandleText: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.BigScreen, value: 22 },
          { minWidth: Breakpoints.Desktop, value: 17 },
        ],
      },
      props: {},
    },
    ResponsiveNameText: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 32 },
          { minWidth: Breakpoints.Mobile, value: 22 },
          { minWidth: Breakpoints.BigScreen, value: 30 },
        ],
      },
      props: {},
    },
    Text: { style: { fontFamily: 'SpaceGrotesk_400Regular' }, props: {} },
    WizardErrorText: {
      style: {
        color: theme.colors.background.danger,
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 11 },
          { minWidth: Breakpoints.Desktop, value: 12 },
        ],
        marginTop: 6,
      },
      props: {},
    },
    WizardPermissonsText: {
      style: {
        color: theme.colors.background.brand,
        fontFamily: 'SpaceGrotesk_300Light',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 11 },
          { minWidth: Breakpoints.Tablet, value: 13 },
          { minWidth: Breakpoints.Laptop, value: 15 },
          { minWidth: Breakpoints.BigScreen, value: 18 },
        ],
        marginBottom: 20,
        marginTop: 30,
        textAlign: 'left',
      },
      props: {},
    },
    WizardTitleText: {
      style: {
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 22 },
          { minWidth: Breakpoints.Laptop, value: 25 },
          { minWidth: Breakpoints.Desktop, value: 32 },
          { minWidth: Breakpoints.BigScreen, value: 37 },
        ],
        marginBottom: 16,
        marginLeft: 0,
      },
      props: {},
    },
    label: {
      style: {
        alignSelf: [
          { minWidth: Breakpoints.Mobile, value: 'auto' },
          { minWidth: Breakpoints.Desktop, value: 'auto' },
        ],
        color: palettes.App['Custom Color'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.BigScreen, value: 15 },
        ],
      },
      props: {},
    },
  });

export const TextInputStyles = theme =>
  StyleSheet.create({
    FormInput: {
      style: {
        borderBottomWidth: 1,
        borderColor: palettes.App['Border Color'],
        borderLeftWidth: 1,
        borderRadius: 10,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: palettes.App['Custom Color'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 16 },
          { minWidth: Breakpoints.Mobile, value: 12 },
        ],
        marginTop: 10,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    TagUploadInput: {
      style: {
        borderBottomWidth: 1,
        borderColor: palettes.App.CardBorder,
        borderLeftWidth: 1,
        borderRadius: 4,
        borderRightWidth: 1,
        borderTopWidth: 1,
        borderWidth: 1,
        color: palettes.App['Custom Color_2'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 12,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Area': {
      style: {
        borderBottomWidth: 1,
        borderColor: palettes.App['Border Color'],
        borderLeftWidth: 1,
        borderRadius: 10,
        borderRightWidth: 1,
        borderTopWidth: 1,
        color: palettes.App['Custom Color'],
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: 12,
        minHeight: 100,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    'Text Input': {
      style: {
        borderBottomWidth: 1,
        borderColor: theme.colors.border.brand,
        borderLeftWidth: 1,
        borderRadius: 8,
        borderRightWidth: 1,
        borderTopWidth: 1,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {},
    },
    WizardTextInput: {
      style: {
        borderColor: palettes.App['Border Color'],
        borderRadius: 48,
        borderWidth: 2,
        color: theme.colors.background.brand,
        fontFamily: 'SpaceGrotesk_500Medium',
        fontSize: [
          { minWidth: Breakpoints.Mobile, value: 12 },
          { minWidth: Breakpoints.Desktop, value: 16 },
        ],
        marginBottom: 10,
        marginTop: 0,
        paddingBottom: 8,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 8,
      },
      props: {
        placeholderTextColor: palettes.App['Border Color'],
      },
    },
  });

export const TabViewStyles = theme =>
  StyleSheet.create({
    ResponsiveTabView: {
      style: {
        flex: 1,
        fontFamily: 'SpaceGrotesk_400Regular',
        fontSize: [
          { minWidth: Breakpoints.Desktop, value: 24 },
          { minWidth: Breakpoints.BigScreen, value: 22 },
        ],
        margin: 8,
      },
      props: {},
    },
  });

  export const BlurViewStyles=theme =>
    StyleSheet.create({
      'Blur View': {
        alignItems: 'center',
        justifyContent: 'center',
        flexBasis: 0,
        flexGrow: 1,
        flexShrink: 1,
        padding: 16,
        '-webkit-backdrop-filter': 'saturate(180%) blur(10px)',
        backdropFilter: 'saturate(180%) blur(10px)'
      },
    });

export const KeyboardAwareScrollViewStyles = theme =>
  StyleSheet.create({
    ResponsiveKeyboardAwareScrollView: {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
  });

export const SurfaceStyles = theme =>
  StyleSheet.create({
    FloatingBackButtonShadow: {
      style: {
        alignItems: 'center',
        backgroundColor: palettes.App.BackgroundPurple,
        borderRadius: 40,
        height: 40,
        justifyContent: 'center',
        left: 20,
        minHeight: 40,
        position: 'absolute',
        top: 10,
        width: 40,
        zIndex: 9999,
      },
      props: {},
    },
    Surface: { style: { minHeight: 40 }, props: {} },
  });

export const SliderStyles = theme =>
  StyleSheet.create({
    Slider: { style: { marginLeft: 12, marginRight: 12 }, props: {} },
  });

export const SwiperStyles = theme =>
  StyleSheet.create({
    Swiper: { style: { height: 300, width: '100%' }, props: {} },
  });

export const DeckSwiperStyles = theme =>
  StyleSheet.create({
    'Deck Swiper': { style: { position: 'absolute' }, props: {} },
  });

export const DeckSwiperCardStyles = theme =>
  StyleSheet.create({
    'Deck Swiper Card': {
      style: {
        alignItems: 'center',
        borderWidth: 2,
        justifyContent: 'center',
        padding: 20,
      },
      props: {},
    },
  });

export const VideoPlayerStyles = theme =>
  StyleSheet.create({ Video: { style: { height: 215 }, props: {} } });

export const ImageBackgroundStyles = theme =>
  StyleSheet.create({ 'Image Background': { style: { flex: 1 }, props: {} } });

export const LinearGradientStyles = theme =>
  StyleSheet.create({ 'Linear Gradient': { style: { flex: 1 }, props: {} } });

export const DatePickerStyles = theme =>
  StyleSheet.create({
    WizardDatePicker: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderColor: theme.colors.background.brand,
        borderRadius: 36,
        borderWidth: 1,
        color: theme.colors.background.brand,
        fontFamily: 'SpaceGrotesk_500Medium',
        fontSize: 12,
        margin: 0,
        padding: 0,
        paddingBottom: 4,
        paddingLeft: 15,
        paddingRight: 8,
        paddingTop: 4,
      },
      props: {
        hideLabel: true,
      },
    },
  });

export const SimpleStyleKeyboardAwareScrollViewStyles = theme =>
  StyleSheet.create({
    'ResponsiveKeyboardAwareScrollView 10': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 2': {
      style: {
        alignItems: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'stretch' },
        ],
        alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
        maxWidth: [
          { minWidth: Breakpoints.Laptop, value: 1024 },
          { minWidth: Breakpoints.Desktop, value: 1024 },
        ],
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 3': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
          { minWidth: Breakpoints.Laptop, value: 'center' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 4': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 5': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 6': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 7': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 8': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
    'ResponsiveKeyboardAwareScrollView 9': {
      style: {
        alignItems: { minWidth: Breakpoints.Desktop, value: 'stretch' },
        alignSelf: [
          { minWidth: Breakpoints.Laptop, value: 'center' },
          { minWidth: Breakpoints.Desktop, value: 'flex-end' },
        ],
        flex: 1,
        maxWidth: { minWidth: Breakpoints.Laptop, value: 1024 },
        minWidth: { minWidth: Breakpoints.Laptop, value: 550 },
        overflow: 'hidden',
        width: { minWidth: Breakpoints.Desktop, value: '100%' },
      },
      props: {},
    },
  });

export const SimpleStyleFlatListStyles = theme =>
  StyleSheet.create({
    'ResponsiveCommentsList 2': {
      style: {
        alignItems: { minWidth: Breakpoints.Tablet, value: 'stretch' },
        alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
        flexDirection: 'column',
      },
      props: {},
    },
    'ResponsiveCommentsList 3': {
      style: {
        alignItems: { minWidth: Breakpoints.Tablet, value: 'stretch' },
        alignSelf: { minWidth: Breakpoints.Tablet, value: 'center' },
        flexDirection: 'column',
      },
      props: {},
    },
  });
