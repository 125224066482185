import React from 'react';
import { Icon, Pressable, SimpleStyleFlatList, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { useAnalytics } from '@segment/analytics-react-native';
import * as Sentry from '@sentry/react-native';
import { Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const defaultProps = {
  currentRoute: { icon: 'Entypo/home', name: 'RouteOne', label: 'Route 1' },
  routes: [
    { icon: 'Entypo/home', name: 'RouteOne', label: 'Route 1' },
    { icon: 'Entypo/home', name: 'RouteTwo', label: 'Route 2' },
    { icon: 'Entypo/home', name: 'RouteThree', label: 'Route 3' },
  ],
};

const CustomNavBarBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const segment = useAnalytics();

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
          backgroundColor: palettes.App.BackgroundPurple,
          height: 50,
          width: '100%',
        },
        dimensions.width
      )}
    >
      <View
        {...GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ViewStyles(theme)['ResponsiveWrapperView'].style,
            {
              paddingBottom: 12,
              paddingTop: 12,
              width: { minWidth: Breakpoints.Desktop, value: dimensions.width },
            }
          ),
          dimensions.width
        )}
      >
        <SimpleStyleFlatList
          data={props.routes ?? defaultProps.routes}
          horizontal={false}
          inverted={false}
          keyExtractor={(listData, index) =>
            listData?.id ??
            listData?.uuid ??
            index?.toString() ??
            JSON.stringify(listData)
          }
          keyboardShouldPersistTaps={'never'}
          listKey={'5peXnhDi'}
          nestedScrollEnabled={false}
          numColumns={1}
          onEndReachedThreshold={0.5}
          renderItem={({ item, index }) => {
            const listData = item;
            return (
              <Pressable
                onPress={() => {
                  try {
                    if (listData?.name === 'ProfileStackNavigator') {
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'ProfileStackNavigator',
                        params: { screen: 'ProfileScreen' },
                      });
                    } else {
                      navigation.navigate(listData?.name);
                    }

                    if (listData?.name === 'HomeStackNavgiator') {
                      navigation.navigate('BottomTabNavigator', {
                        screen: 'HomeStackNavgiator',
                        params: { screen: 'HomepageScreen' },
                      });
                    } else {
                      navigation.navigate(listData?.name);
                    }
                  } catch (err) {
                    Sentry.captureException(err);
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={
                      (listData?.name ===
                      (props.currentRoute?.name ??
                        {
                          icon: 'Entypo/home',
                          name: 'RouteOne',
                          label: 'Route 1',
                        }.name)
                        ? palettes.Brand.Primary
                        : palettes.Brand.Light) ?? theme.colors.foreground.brand
                    }
                    name={listData?.icon}
                  />
                </View>
              </Pressable>
            );
          }}
          showsHorizontalScrollIndicator={true}
          showsVerticalScrollIndicator={true}
          scrollEnabled={false}
          style={StyleSheet.applyWidth(
            { flexDirection: 'row', justifyContent: 'space-evenly' },
            dimensions.width
          )}
        />
      </View>
    </View>
  );
};

export default withTheme(CustomNavBarBlock);