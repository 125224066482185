import palettes from '../themes/palettes';
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
  ActivityIndicator,
  Text,
  View,
  TouchableOpacity,
  Platform,
  useWindowDimensions,
  FlatList,
  TextInput,
} from 'react-native';
import { Card } from './UI';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import { useAnalytics } from '@segment/analytics-react-native';
import { MasonryList } from './MasonryList';
import algoliasearch from 'algoliasearch';
import { useDebounce } from './Hooks';

import {
  InstantSearch,
  connectInfiniteHits,
  connectSearchBox,
  Configure,
} from 'react-instantsearch-core';
import initialize_isLikedByUserCache from '../global-functions/initialize_isLikedByUserCache';
import { Suggestions } from './Suggestions';
import { IconButton } from '@draftbit/ui';

const searchClient = algoliasearch(
  '68GP37SS9I',
  'a7be4b16c32c9356c462bc904c75b5e9'
);

const ads_endpoint = 'https://x8x4-ck6z-yoac.n7.xano.io/api:Rs2ZlEBK/get_ads';
let isFetchingAds = false;

export const List = ({
  theme,
  navigation,
  searchQuery = '',
  setSearchQuery,
  searchbarFocued,
  promotion_id,
  promotionCriteria = {},
  initialFacets = [],
  tags_id,
  showDownloadModal,
  forceShow,
}) => {
  //  console.log(promotion_id, searchQuery, promotionCriteria, initialFacets);

  const variables = GlobalVariables.useValues();
  const setVariables = GlobalVariables.useSetValue();
  const segment = useAnalytics();
  const listRef = useRef(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 650);
  const hidden_user_ids =
    (variables?.USER?._hidden_user_ids_of_user || [])?.map(
      item => item?.hidden_user_id
    ) || [];

  const [subItemFacet, setSubItemFacet] = useState(initialFacets);

  // console.log('subItemFacet', subItemFacet)

  function scrollToTop() {
    listRef.current?.scrollToOffset({ animated: false, offset: 0 });
  }

  const dimensions = useWindowDimensions();

  const numColumns =
    dimensions.width < Breakpoints.Tablet
      ? 2
      : dimensions.width < Breakpoints.Laptop
      ? 3
      : 4;

  useEffect(() => {
    if (
      Object.keys(variables.isLikedByUserCache).length === 0 &&
      variables?.AUTH_TOKEN
    ) {
      initialize_isLikedByUserCache(
        variables,
        setVariables,
        variables?.AUTH_TOKEN
      );
    }
  }, [variables.isLikedByUserCache, variables?.AUTH_TOKEN]);

  useEffect(() => {
    listRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [searchQuery, promotionCriteria]);

  useEffect(() => {
    if (initialFacets)
      //  && initialFacets.length > 0
      setSubItemFacet(initialFacets);
  }, [initialFacets]);

  const convertCriteriaToFaceFilters = promotionCriteria => {
    const contentKeys = [
      'actor',
      'character',
      'episode',
      'genre',
      'network',
      'series_name',
    ];
    const retailKeys = [
      'brand',
      'category',
      'gender',
      'product_name',
      'retailer',
    ];

    return Object.keys(promotionCriteria).reduce((acc, cur) => {
      if (promotionCriteria[cur]) {
        if (contentKeys.includes(cur)) {
          if (Array.isArray(promotionCriteria[cur])) {
            const orArray = promotionCriteria[cur].map(val => {
              return `_tag_content_details.${cur}:${val}`;
            });
            acc.push(orArray);
          } else
            acc.push([`_tag_content_details.${cur}:${promotionCriteria[cur]}`]);
        }

        if (retailKeys.includes(cur)) {
          if (Array.isArray(promotionCriteria[cur])) {
            const orArray = promotionCriteria[cur].map(val => {
              return `_tag_retail_details.${cur}:${val}`;
            });
            acc.push(orArray);
          } else
            acc.push([`_tag_retail_details.${cur}:${promotionCriteria[cur]}`]);
        }
      }

      return acc;
    }, []);
  };

  const convertCriteriaToNumericFilter = promotionCriteria => {
    const created_at = promotionCriteria?.created_at;
    if (!created_at) return [];
    const [min, max] = created_at;
    return [`created_at:${min} TO ${max}`];
  };

  return (
    <View style={{ flex: 1 }}>
      <InstantSearch searchClient={searchClient} indexName="tags">
        <Configure
          facetFilters={[
            ...convertCriteriaToFaceFilters(promotionCriteria),
            ...subItemFacet,
          ]}
          highlightPreTag="<mark>"
          highlightPostTag="</mark>"
          filters={`NOT blocked_user_id:${variables?.USER?.id} ${
            hidden_user_ids.length > 0
              ? 'AND NOT tagger_id:' +
                hidden_user_ids.join(' AND NOT tagger_id:')
              : ''
          }`}
          numericFilters={[
            ...convertCriteriaToNumericFilter(promotionCriteria),
          ]}
        />
        <SearchBox
          searchQuery={debouncedSearchQuery}
          setSearchQuery={setSearchQuery}
          searchbarFocued={searchbarFocued}
          setSubItemFacet={setSubItemFacet}
          facetFilters={convertCriteriaToFaceFilters(promotionCriteria)}
          onChange={scrollToTop}
          forceShow={forceShow}
        />
        <CurrentRefinement
          facetFilters={subItemFacet}
          setFacetFilters={setSubItemFacet}
          theme={theme}
          forceShow={forceShow}
        />

        <InfiniteHits
          forwardRef={listRef}
          searchQuery={debouncedSearchQuery}
          facetFilters={subItemFacet}
          numColumns={numColumns}
          promotion_id={promotion_id}
          tags_id={tags_id}
          theme={theme}
          navigation={navigation}
          showDownloadModal={showDownloadModal}
        />
      </InstantSearch>
    </View>
  );
};

const InfiniteHits = connectInfiniteHits(
  forwardRef(
    (
      {
        searchQuery,
        facetFilters,
        numColumns,
        promotion_id = 0,
        tags_id = 0,
        showDownloadModal,
        theme,
        navigation,
        forwardRef,
        hits,
        hasMore,
        refineNext,
        ...props
      },
      ref
    ) => {
      const dimensions = useWindowDimensions();
      const variables = GlobalVariables.useValues();
      const setVariables = GlobalVariables.useSetValue();
      const safeTextInputRef = React.useRef();
      const injectAt = 7;

      const screen = tags_id
        ? 'related_tags'
        : searchQuery
        ? 'search_results'
        : 'homepage';

      const [renderedItemLayouts, setRenderedItemLayouts] = useState({});
      const [visibleItems, setVisibleItems] = useState([]);
      const [adsData, setAdsData] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);

      const injectedAds = React.useMemo(() => {
        if (hits.length === 0) return [];
        if (adsData?.length === 0 || !adsData) return hits;

        const adsDataCopy = [...adsData]; // Create a copy of adsData
        const newHits = hits.reduce((acc, hit, hitIndex) => {
          if (
            hitIndex % injectAt === 0 &&
            hitIndex !== 0 &&
            adsDataCopy.length > 0
          ) {
            const dataToInject = adsDataCopy.shift(); // Use the copy for shift()
            acc.push({
              ...dataToInject,
              type: 'ad',
              uniqueId: Math.round(Math.random() * 1000000),
            });
          }
          acc.push(hit);
          return acc;
        }, []);
        return newHits;
      }, [hits, injectAt, adsData]);

      const fetchAds = async ({
        searchQuery,
        promotion_id,
        tags_count,
        screen,
        tags_id,
      }) => {
        try {
          if (isFetchingAds) return;

          isFetchingAds = true;
          const response = await fetch(
            `${ads_endpoint}?searchQuery=${searchQuery}&promotion_id=${promotion_id}&tags_count=${tags_count}&screen=${screen}&tags_id=${tags_id}`
          );
          const data = await response.json();
          isFetchingAds = false;
          return data;
        } catch (e) {
          console.log(e);
        }
      };

      const updateCaches = itemsResult => {
        const likesCache = Object.assign({}, variables?.likesCache || {});
        const sharesCache = Object.assign({}, variables?.sharesCache || {});

        itemsResult.forEach(item => {
          const tagID = item.id;
          likesCache[tagID] = Math.max(item?._likes_of_tags, 0);
          sharesCache[tagID] = Math.max(item?._share_count, 0);
        });

        if (
          JSON.stringify(Object.keys(likesCache)) !==
          JSON.stringify(Object.keys(variables?.likesCache))
        ) {
          setVariables({ key: 'likesCache', value: likesCache });
        }
        if (
          JSON.stringify(sharesCache) !== JSON.stringify(variables?.sharesCache)
        ) {
          setVariables({ key: 'sharesCache', value: sharesCache });
        }
      };

      const handleScroll = ({ nativeEvent }) => {
        // check each item in renderedItemLayouts to see if it is visible, set the visibleItems with the ids of the visible items
        const { contentOffset, layoutMeasurement } = nativeEvent;
        const { y: scrollY } = contentOffset;
        const { height } = layoutMeasurement;
        const vi = [];
        safeTextInputRef.current?.blur();

        if (Object.keys(renderedItemLayouts).length === 0) return;

        Object.keys(renderedItemLayouts).forEach(key => {
          const { y: itemY, height: itemHeight } = renderedItemLayouts[key];
          const isVisible =
            itemY - scrollY >= 0 && itemY - scrollY <= height - itemHeight;

          if (isVisible) vi.push(parseInt(key));
        });
        setVisibleItems(vi);
      };

      React.useEffect(() => {
        if (hits.length === 0) return;

        updateCaches(hits);
      }, [hits.map(item => item.id)]);

      React.useEffect(() => {
        const fetchAdsSync = async () => {
          const itemsResult = await fetchAds({
            searchQuery,
            promotion_id,
            tags_count: 25,
            screen,
            tags_id,
          });
          setAdsData(prev => [...(prev || []), ...(itemsResult || [])]);
        };

        fetchAdsSync();
      }, [searchQuery, promotion_id, tags_id, currentPage]);

      return (
        <>
          {/**
          <TextInput
            ref={safeTextInputRef}
            style={{
            height: 40,
            margin: 12,
            borderWidth: 1,
            padding: 10,
            backgroundColor:'white'
            }}
          />
           */}
          <MasonryList
            data={injectedAds}
            innerRef={forwardRef}
            keyExtractor={item => item.id}
            numColumns={numColumns}
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled={true}
            renderItem={({ item, i }) => {
              return (
                <TouchableOpacity
                  onPress={() => {
                    if (item.type !== 'ad') {
                      if (item.validated) {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'HomeStackNavgiator',
                          params: {
                            screen: 'ClickedTagPageScreen',
                            params: {
                              tagID: item.id,
                            },
                          },
                          key:
                            'ClickedTagPageScreen' +
                            Math.round(Math.random() * 1000000),
                        });
                      } else {
                        navigation.navigate('BottomTabNavigator', {
                          screen: 'HomeStackNavgiator',
                          params: {
                            screen: 'UnverifiedTagPreviewScreen',
                            params: {
                              tagID: item.id,
                            },
                          },
                          key:
                            'UnverifiedTagPreviewScreen' +
                            Math.round(Math.random() * 1000000),
                        });
                      }
                    }
                  }}
                  style={{
                    marginBottom: Platform.OS === 'web' ? 8 : undefined,
                  }}
                  key={`${item.type === 'ad' ? 'ad' : 'card'}-${item.id}-${i}`}
                  onLayout={e => {
                    if (item.type !== 'ad') {
                      if (renderedItemLayouts.length > 0)
                        setRenderedItemLayouts({});

                      return;
                    }
                    const { y, height } = e.nativeEvent.layout;
                    setRenderedItemLayouts(prev => {
                      return {
                        ...prev,
                        [item.uniqueId]: { y, height },
                      };
                    });
                  }}
                >
                  <Card
                    item={item}
                    theme={theme}
                    navigation={navigation}
                    showDownloadModal={showDownloadModal}
                    index={i}
                    isVisible={visibleItems.includes(item.uniqueId)}
                  />
                </TouchableOpacity>
              );
            }}
            // refreshing={isLoading}
            LoadingView={() => <ActivityIndicator />}
            ListEmptyComponent={() => {
              if (searchQuery || facetFilters.length > 0)
                return (
                  <View
                    style={{
                      flex: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: dimensions.height / 3,
                    }}
                  >
                    <Text
                      style={{
                        color: theme.colors.background.brand,
                        fontSize: 16,
                        textAlign: 'center',
                      }}
                    >
                      Your search did not return any results. Try searching for
                      a TV network, TV show, actor, or clothing brand
                    </Text>
                  </View>
                );

              return null;
            }}
            // onEndReachedThreshold={0.01}
            onEndReached={() => {
              if (hasMore) {
                setCurrentPage(prev => prev + 1);
                refineNext && refineNext();
              }
            }}
            onScroll={handleScroll}
          />
        </>
      );
    }
  )
);

const SearchBox = connectSearchBox(
  ({
    searchQuery,
    setSearchQuery,
    searchbarFocued,
    facetFilters,
    setSubItemFacet,
    refine,
    forceShow,
  }) => {
    const [showSuggestions, setShowSuggestions] = useState(true);

    useEffect(() => {
      if (refine) {
        refine(searchQuery);
      }
    }, [searchQuery, refine]);

    useEffect(() => {
      setTimeout(() => {
        setShowSuggestions(searchbarFocued);
      }, 500);

      // if (!searchbarFocued) {
      //   forceShow(false);
      // }
    }, [searchbarFocued]);

    function setQuery(newQuery) {
      refine(`${newQuery}`);
      setSearchQuery(`${newQuery}`);
    }
    // if (facetFilters.length === 0)
    return (
      <>
        <Suggestions
          indexName={'tags_query_suggestions'}
          searchQuery={searchQuery}
          setItemsCount={count => {}}
          onSelect={value => {
            setQuery(value);
            setShowSuggestions(false);
          }}
          onSubItemPress={subValue => {
            setShowSuggestions(false);
            refine(``);
            setSearchQuery(``);
            forceShow(true);
            setSubItemFacet([`${subValue.facet}:${subValue.value}`]);
          }}
          show={showSuggestions}
        />
      </>
    );
  }
);

const CurrentRefinement = ({
  facetFilters,
  theme,
  setFacetFilters,
  forceShow,
}) => {
  return (
    <View>
      <FlatList
        data={facetFilters}
        renderItem={({ item, index }) => {
          const [facet, value] = item.split(':');
          return (
            <View
              style={{
                backgroundColor: theme.colors.branding.primary,
                alignItems: 'center',
                borderRadius: 24,
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 5,
                minHeight: 30,
                padding: 5,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Text
                accessible={true}
                style={{
                  fontFamily: 'SpaceGrotesk_400Regular',
                  color: palettes.App['Custom Color_2'],
                  fontSize: 14,
                }}
              >
                {facetTranslator[facet]}: {value}
              </Text>

              <IconButton
                icon="close"
                color={palettes.App['Custom Color_2']}
                size={18}
                onPress={() => {
                  forceShow(false);
                  setFacetFilters(prev =>
                    prev.filter(item => item !== `${facet}:${value}`)
                  );
                }}
              />
            </View>
          );
        }}
        keyExtractor={(item, index) => `${item}-${index}`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

const facetTranslator = {
  '_tag_content_details.actor': 'Actor',
  '_tag_content_details.character': 'Character',
  '_tag_content_details.episode': 'Episode',
  '_tag_content_details.genre': 'Genre',
  '_tag_content_details.network': 'Network',
  '_tag_content_details.series_name': 'Series',
  '_tag_retail_details.brand': 'Brand',
  '_tag_retail_details.category': 'Category',
  '_tag_retail_details.gender': 'Gender',
  '_tag_retail_details.product_name': 'Product',
  '_tag_retail_details.retailer': 'Retailer',
};
