/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';
function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}
const prefix = renderLinkingPrefix();
const linking = {
  prefixes: [prefix],
  config: {
    screens: {
      WelcomeScreen: 'welcome/:escape?',
      SignUpScreen: 'sign-up/:escape?',
      LoginScreen: 'login/:escape?',
      TermsAndConditionsScreen: 'terms-and-conditions/:escape?',
      PrivacyPolicyScreen: 'privacy-policy/:escape?',
      CompleteOnboardingScreen: 'complete-onboarding/:escape?',
      ForgotPasswordScreen: 'forgot-password/:escape?',
      ForgotPasswordUpdateScreen: 'update-password/:escape?',
      UnsubscribeScreen: 'unsubscribe/:token?',
      BottomTabNavigator: {
        screens: {
          TagUploadScreen: 'upload-tag/:escape?',
          ProfileStackNavigator: {
            screens: {
              ProfileScreen: 'profile/:initialTabIndex?',
              EditProfileScreen: 'edit-profile/:escape?',
            },
          },
          HomeStackNavgiator: {
            screens: {
              TagCommentPageScreen: 'comment/:ClickedTagID?',
              ClickedTagPageScreen: 'tag/:tagID?',
              TaggerProfileScreen: 'tagger/:taggerId?',
              UnverifiedTagPreviewScreen: 'preview/:tagID?',
              HomepageScreen: {
                path: 'search',
                parse: {
                  pid: pid => pid === 'null' ? undefined : pid,
                  query: query => query === 'null' ? undefined : query,
                  initialFacets: initialFacets => initialFacets ? (initialFacets || [])?.split(',').map(decodeURIComponent) : [],
                },
                stringify: {
                  pid: pid => pid || undefined,
                  query: query => query || undefined,
                  initialFacets: initialFacets => (initialFacets || [])?.map(encodeURIComponent).join(','),
                },
              },
            },
          },
          DiscoverScreen:{
                path: '/:pid?/:query?',
                exact: true,
              },
        },
      },
    },
  },
}

export default linking;