const goBack = (navigation, Variables, setGlobalVariableValue) => {
  let history = Variables?.HISTORY || [];
  // console.log(history)
  //first remove the current screen from history
  history.pop();

  //then go back to the previous screen
  const previousScreen = history.pop();
  setGlobalVariableValue({
    key: 'HISTORY',
    value: history,
  });

  // console.log(previousScreen, navigation.navigate)

  if (previousScreen) {
    navigation?.navigate(previousScreen.screenName, previousScreen.params);
  }
};

export default goBack;
