const updateGlobalShares = (
  Variables,
  setGlobalVariableValue,
  id,
  newValue
) => {
  const sharesCache = Variables?.sharesCache || {};
  sharesCache[id] = newValue;
  sharesCache[id] = Math.max(sharesCache[id], 0);
  setGlobalVariableValue({ key: 'sharesCache', value: sharesCache });
};

export default updateGlobalShares;
