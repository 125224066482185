import React from 'react';

import { TourGuideZone, TourGuideZoneByPosition } from 'rn-tourguide';

import { Text, Image, View } from 'react-native';

// interface TourGuideZoneProps {
//   zone: number // A positive number indicating the order of the step in the entire walkthrough.
//   tourKey?: string // A string indicating which tour the zone belongs to
//   isTourGuide?: boolean // return children without wrapping id false
//   text?: string // text in tooltip
//   shape?:'circle' | 'rectangle' | 'circle_and_keep' | 'rectangle_and_keep'
//   maskOffset?: number // offset around zone
//   borderRadius?: number // round corner when rectangle
//   keepTooltipPosition?: boolean
//   tooltipBottomOffset?: number
//   children: React.ReactNode
// }
export const WrapperZone = ({
  children,
  text,
  shape = 'rectangle',
  zone = 1,
  isTourGuide = true,
  maskOffset,
  borderRadius = 0,
  tooltipBottomOffset = 0,
  width,
  height,
}) => {
  return (
    <TourGuideZone
      text={text}
      zone={zone}
      shape={shape}
      isTourGuide={isTourGuide}
      borderRadius={borderRadius}
      tooltipBottomOffset={tooltipBottomOffset}
      maskOffset={maskOffset}
      width={width}
      height={height}
    >
      {children}
    </TourGuideZone>
  );
};

export const WrapperZonePosition = ({
  text,
  shape = 'rectangle',
  zone = 1,
  isTourGuide,
  bottom,
  left,
  width,
  height,
}) => {
  return (
    <TourGuideZoneByPosition
      text={text}
      zone={zone}
      shape={shape}
      isTourGuide={isTourGuide}
      bottom={bottom}
      left={left}
      width={width}
      height={height}
    />
  );
};
