import { Platform } from 'react-native';

const trackAction = (Variables, segment, action, properties) => {
  try {
    // console.log('segment track action', action, properties, Variables)
    segment.track(action, {
      ...properties,
      platform: Platform.OS,
      user: {
        email: Variables?.USER?.email || undefined,
        handle: Variables?.USER?._profile_of_user?.handle || undefined,
        gender: Variables?.USER?._profile_of_user?.gender || undefined,
        location: Variables?.USER?._profile_of_user?.location || undefined,
        interests:
          Variables?.USER?._profile_of_user?.interests_id
            ?.map(interest => interest.title)
            .join(', ') || undefined,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export default trackAction;
